import {MdDeleteOutline} from "react-icons/md";

const TablePresensi = ({tableData, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }

  const capitalizeEachWord = (sentence) => {
    const words = sentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  return (
    <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
      <thead className="sticky top-0">
        <tr className="bg-[#F9F9FC] text-black text-left">
          {!!currentPage && <th className="py-2 whitespace-nowrap">No</th>}
          <th className="py-2 whitespace-nowrap">Employee Name</th>
          <th className="py-2 whitespace-nowrap">Shift</th>
          <th className="py-2 whitespace-nowrap">Status</th>
          <th className="py-2 whitespace-nowrap">Coming Time</th>
          <th className="py-2 whitespace-nowrap">Return Time</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 &&
          tableData.map((row) => (
            <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
              {!!currentPage && (
                <td className="py-2 whitespace-nowrap">{startNumber++}</td>
              )}
              <td className="py-2 whitespace-nowrap">{row.employee_name}</td>
              <td className="py-2 whitespace-nowrap">
                {capitalizeEachWord(row.shift)}
              </td>
              <td className="py-2 whitespace-nowrap">
                {capitalizeEachWord(row.status)}
              </td>
              <td className="py-2 whitespace-nowrap">{row.coming_time}</td>
              <td className="py-2 whitespace-nowrap">{row.return_time}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TablePresensi;
