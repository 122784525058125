import {useEffect, useState} from "react";
import TabTitle from "../utils/GeneralFunction";
import axios from "axios";
import Pagination from "../components/Pagination";
import TableLoglogin from "../components/TableLoglogin";
import Session from "../Session";
import ModalAlert from "../components/ModalAlert";
import { API_URL } from '../utils/api';

const SettingLogLogin = () => {
  TabTitle("Log - Kato Haircut");
  // Modal
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setMessage("");
  };
  const [message, setMessage] = useState("");
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Filter
  const [dateFilter, setDateFilter] = useState("");

  const fetchData = async (page = currentTablePage, dateFilter) => {
    try {
      const {data} = await axios.get(
        `${API_URL}admin/login-activities${
          dateFilter !== "" && dateFilter !== undefined
            ? `?datetime=${dateFilter}&page=${page}`
            : `?page=${page}`
        }`,
        Session()
      );
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateFilter = (val) => {
    setCurrentTablePage(1);
    setDateFilter(val);
    fetchData(1, val);
  };

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page, dateFilter);
  };

  return (
    <div className="w-full h-full flex flex-col grow overflow-auto scrollbar-shown">
      <ModalAlert show={openAlert} close={closeAlertModal} message={message} />
      <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col gap-4">
        <div className="flex flex-row mt-2 md:mt-0">
          <div className="relative">
            <input
              type="date"
              name="from"
              id="dateFilter"
              value={dateFilter}
              className="h-10 border border-gray-300 text-sm bg-white rounded-lg px-2 outline-none focus:ring-black focus:ring-2 text-black"
              onChange={(e) => handleDateFilter(e.target.value)}
            />
          </div>
        </div>
        {tableCount ? (
          <>
            <div className="h-full mb-3 overflow-y-auto scrollbar-shown">
              <TableLoglogin
                tableData={tableData}
                currentPage={currentTablePage}
              />
            </div>
            <Pagination
              maxPage={Math.ceil(tableCount / itemsPerPage)}
              currentPage={currentTablePage}
              showTablePage={showTablePage}
            />
          </>
        ) : (
          <p className="w-full text-black">Waiting for Data</p>
        )}
      </div>
    </div>
  );
};

export default SettingLogLogin;
