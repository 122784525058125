import axios from "axios";
import React, {useEffect, useState} from "react";
import {MdAdd} from "react-icons/md";
import ModalAlert from "../components/ModalAlert";
import ModalCreateActivity from "../components/ModalCreateActivity";
import ModalDeleteActivity from "../components/ModalDeleteActivity";
import ModalEditActivity from "../components/ModalEditActivity";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import TableActivity from "../components/TableActivity";
import Session from "../Session";
import TabTitle from "../utils/GeneralFunction";
import { API_URL } from '../utils/api';

const ProductActivity = () => {
  TabTitle("Commission - Kato Haircut");
  // Modal
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  const [openCreateActivity, setOpenCreateActivity] = useState(false);
  const openCreateActivityModal = () => setOpenCreateActivity(true);
  const closeCreateActivityModal = () => setOpenCreateActivity(false);
  const [openEditActivity, setOpenEditActivity] = useState(false);
  const openEditActivityModal = () => setOpenEditActivity(true);
  const closeEditActivityModal = () => setOpenEditActivity(false);
  const [openDeleteActivity, setOpenDeleteActivity] = useState(false);
  const openDeleteActivityModal = () => setOpenDeleteActivity(true);
  const closeDeleteActivityModal = () => setOpenDeleteActivity(false);
  const [errorMsg, setErrorMsg] = useState("");
  // Data & Table & Pagination
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Handle Create
  const [nameActivity, setNameActivity] = useState("");
  const [commissionType, setCommissionType] = useState("nominal");
  const [commissionFee, setCommissionFee] = useState("");
  // Handle Edit
  const [editIdActivity, setEditIdActivity] = useState("");
  const [editCodeActivity, setEditCodeActivity] = useState("");
  const [editNameActivity, setEditNameActivity] = useState("");
  const [editCommissionType, setEditCommissionType] = useState("nominal");
  const [editCommissionFee, setEditCommissionFee] = useState("");
  // Handle Delete
  const [deleteIdActivity, setDeleteIdActivity] = useState("");
  const [deleteNameActivity, setDeleteNameActivity] = useState("");
  // Function
  const fetchData = async (name, page) => {
    try {
      const {data} = await axios.get(
        `${API_URL}activities${
          name !== "" && name !== undefined
            ? page !== "" && page !== undefined
              ? `?name=${name}&page=${page}`
              : `?name=${name}`
            : page !== "" && page !== undefined
            ? `?page=${page}`
            : ``
        }`,
        Session()
      );
      setTableData(data.data);
      setTotalItems(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleSubmit = async (val) => {
    val.preventDefault();
    try {
      await axios.post(
        `${API_URL}activities/create`,
        {
          name: nameActivity,
          type: commissionType,
          amount: commissionFee,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully added activity, if data didn't show you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const prepareEdit = async (val) => {
    try {
      const {data} = await axios.get(
        `${API_URL}activities/${val}`,
        Session()
      );
      setEditIdActivity(val);
      setEditCodeActivity(data.data.code);
      setEditNameActivity(data.data.name);
      setEditCommissionType(data.data.type);
      setEditCommissionFee(data.data.amount);
      openEditActivityModal();
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleEdit = async (val) => {
    val.preventDefault();
    try {
      await axios.put(
        `${API_URL}activities/update/${editIdActivity}`,
        {
          code: editCodeActivity,
          name: editNameActivity,
          type: editCommissionType,
          amount: editCommissionFee,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully update activity, if data didn't update you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const prepareDelete = async (val) => {
    try {
      const {data} = await axios.get(
        `${API_URL}activities/${val}`,
        Session()
      );
      setDeleteIdActivity(val);
      setDeleteNameActivity(data.data.name);
      openDeleteActivityModal();
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async (e) => {
    try {
      await axios.delete(
        `${API_URL}activities/delete/${deleteIdActivity}`,
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully delete activity, if data didn't update you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const showSearchedTablePage = (val) => {
    setSearchValue(val);
    setCurrentPage(1);
    fetchData(val, 1);
  };

  const showTablePage = (page) => {
    setCurrentPage(page);
    fetchData(searchValue, page);
  };

  useEffect(() => {
    fetchData(searchValue, currentPage);
  }, []);

  return (
    <div className="w-full h-full flex flex-col grow overflow-auto scrollbar-shown">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <ModalCreateActivity
        show={openCreateActivity}
        close={closeCreateActivityModal}
        submit={handleSubmit}
        nameActivityValue={nameActivity}
        setNameActivityValue={setNameActivity}
        commissionType={commissionType}
        setCommissionType={setCommissionType}
        feeCommissionValue={commissionFee}
        setFeeCommissionValue={setCommissionFee}
      />
      <ModalEditActivity
        show={openEditActivity}
        close={closeEditActivityModal}
        submit={handleEdit}
        nameActivityValue={editNameActivity}
        setNameActivityValue={setEditNameActivity}
        commissionType={editCommissionType}
        setCommissionType={setEditCommissionType}
        feeCommissionValue={editCommissionFee}
        setFeeCommissionValue={setEditCommissionFee}
      />
      <ModalDeleteActivity
        show={openDeleteActivity}
        close={closeDeleteActivityModal}
        submit={handleDelete}
        nameDeleteValue={deleteNameActivity}
      />
      <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col">
        <div className="flex flex-row justify-between sm:justify-start font-nunito-sans">
          <Search
            textColor={"text-black"}
            bgColor={"bg-white"}
            placeholder={"Search by name..."}
            searchValue={searchValue}
            setSearchValue={showSearchedTablePage}
          />
          <button
            type="submit"
            className="flex flex-row ml-2 items-center sm:ml-auto h-10 px-3 py-2 bg-black rounded-lg"
            onClick={openCreateActivityModal}
          >
            <MdAdd className="text-white mr-0 sm:mr-2" />
            <span className="w-full text-sm sm:text-normal whitespace-pre sm:whitespace-normal">
              Add Activity
            </span>
          </button>
        </div>
        {totalItems ? (
          <>
            <div className="h-full mb-3 overflow-y-auto scrollbar-shown">
              <TableActivity
                tableData={tableData}
                editRow={prepareEdit}
                deleteRow={prepareDelete}
                currentPage={currentPage}
              />
            </div>
            <Pagination
              maxPage={Math.ceil(totalItems / itemsPerPage)}
              currentPage={currentPage}
              showTablePage={showTablePage}
            />
          </>
        ) : (
          <p className="w-full text-black">Waiting for Data</p>
        )}
      </div>
    </div>
  );
};

export default ProductActivity;
