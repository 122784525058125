import {MdAdd} from "react-icons/md";
import {useEffect, useState} from "react";
import TabTitle from "../utils/GeneralFunction";
import TableAdmin from "../components/TableAdmin";
import axios from "axios";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import ModalCreateAdmim from "../components/ModalCreateAdmin";
import ModalEditAdmin from "../components/ModalEditAdmin";
import ModalDeleteAdmin from "../components/ModalDeleteAdmin";
import Session from "../Session";
import ModalAlert from "../components/ModalAlert";
import { API_URL } from '../utils/api';

const SettingAdmin = () => {
  TabTitle("Admin - Katto Haircut");
  // Modal
  const [openAddAdmin, setOpenAddAdmin] = useState(false);
  const closeAddAdminModal = () => setOpenAddAdmin(false);
  const openAddAdminModal = () => setOpenAddAdmin(true);
  const [openEditAdmin, setOpenEditAdmin] = useState(false);
  const closeEditAdminModal = () => setOpenEditAdmin(false);
  const [openDeleteAdmin, setOpenDeleteAdmin] = useState(false);
  const closeDeleteAdminModal = () => setOpenDeleteAdmin(false);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Search
  const [searchValue, setSearchValue] = useState();
  // Handle Create
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  //Handle Edit
  const [idEdit, setIdEdit] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [usernameEdit, setUsernameEdit] = useState("");
  const [passwordEdit, setPasswordEdit] = useState("");
  const [roleEdit, setRoleEdit] = useState("");
  // Handle Delete
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const fetchData = async (page = currentTablePage, search = "") => {
    try {
      const {data} = await axios.get(
        `${API_URL}admin${
          search !== "" ? `?name=${search}&?page=${page}` : `?page=${page}`
        }`,
        Session()
      );
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page);
  };

  const showSearchedTablePage = (searchValue) => {
    setSearchValue(searchValue);
    setCurrentTablePage(1);
    fetchData(currentTablePage, searchValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${API_URL}admin/create`,
        {
          username: username,
          phone: phone,
          password: password,
          role: role,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully added admin, if data didn't show you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed add admin");
      }
      setOpenAlert(true);
    }
  };

  const prepareEdit = (value) => {
    setIdEdit(value);
    getEditData(value);
    setOpenEditAdmin(true);
  };

  const getEditData = async (value) => {
    try {
      const {data} = await axios.get(
        `${API_URL}admin/${value}}`,
        Session()
      );
      setPhoneEdit(data.data.phone);
      setUsernameEdit(data.data.username);
      setPasswordEdit(data.data.password);
      setRoleEdit(data.data.role);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${API_URL}admin/update/${idEdit}`,
        {
          username: usernameEdit,
          phone: phoneEdit,
          password: passwordEdit,
          role: roleEdit,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully edited admin data, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed update data");
      }
      setOpenAlert(true);
    }
  };

  const prepareDelete = (id) => {
    setIdDelete(id);
    getDeleteData(id);
    setOpenDeleteAdmin(true);
  };

  const getDeleteData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}admin/${id}`,
        Session()
      );
      setNameDelete(data.data.name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("There is an error, please refresh or login again");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}admin/delete/${idDelete}`,
        Session()
      );
      fetchData();
      setErrorMsg("Succesfully deleted admin");
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Delete Data");
      }
      setOpenAlert(true);
    }
  };

  return (
    <div className="w-full h-full flex flex-col grow overflow-auto scrollbar-shown">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <ModalCreateAdmim
        show={openAddAdmin}
        close={closeAddAdminModal}
        phone={phone}
        setPhoneValue={setPhone}
        username={username}
        setUsernameValue={setUsername}
        password={password}
        setPasswordValue={setPassword}
        role={role}
        setRoleValue={setRole}
        submit={handleSubmit}
      />
      <ModalEditAdmin
        show={openEditAdmin}
        close={closeEditAdminModal}
        phoneEditValue={phoneEdit}
        setPhoneEditValue={setPhoneEdit}
        usernameEditValue={usernameEdit}
        setUsernameEditValue={setUsernameEdit}
        passwordEditValue={passwordEdit}
        setPasswordEditValue={setPasswordEdit}
        roleEditValue={roleEdit}
        setRoleEditValue={setRoleEdit}
        submit={handleEdit}
      />
      <ModalDeleteAdmin
        show={openDeleteAdmin}
        close={closeDeleteAdminModal}
        nameDeleteValue={nameDelete}
        submit={handleDelete}
      />
      <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col">
        <div className="flex justify-between">
          <Search
            textColor={"text-black"}
            bgColor={"bg-white"}
            placeholder={"Search by name..."}
            searchValue={searchValue}
            setSearchValue={showSearchedTablePage}
          />
          <button
            type="submit"
            className="flex flex-row ml-2 items-center sm:ml-auto h-10 px-3 py-2 bg-black rounded-lg"
            onClick={openAddAdminModal}
          >
            <MdAdd className="text-white mr-2" />
            <span>Add Admin</span>
          </button>
        </div>
        {tableCount ? (
          <>
            <div className="h-full mb-3 overflow-y-auto scrollbar-shown">
              <TableAdmin
                tableData={tableData}
                editRow={prepareEdit}
                deleteRow={prepareDelete}
                currentPage={currentTablePage}
              />
            </div>
            <Pagination
              maxPage={Math.ceil(tableCount / itemsPerPage)}
              currentPage={currentTablePage}
              showTablePage={showTablePage}
            />
          </>
        ) : (
          <p className="w-full text-black">Waiting for Data</p>
        )}
      </div>
    </div>
  );
};

export default SettingAdmin;
