import {MdAdd} from "react-icons/md";
import React, {useState, useEffect} from "react";
import TabTitle from "../utils/GeneralFunction";
import TableListProducts from "../components/TableListProducts";
import axios from "axios";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import DropdownSelect from "../components/DropdownSelect";
import DropdownOption from "../components/DropdownOption";
import DropdownMenuExport from "../components/DropdownMenuExport";
import FilterByDate from "../components/FilterByDate";
import {utils, writeFileXLSX} from "xlsx";
import PrintReport from "../utils/PrintReport";
import ProductCategoryList from "../components/ProductCategoryList";
import ModalCreateProduct from "../components/ModalCreateProduct";
import ModalDeleteProduct from "../components/ModalDeleteProduct";
import ModalEditProduct from "../components/ModalEditProduct";
import Session from "../Session";
import ModalAlert from "../components/ModalAlert";
import { API_URL } from '../utils/api';
import TableProductSell from "../components/TableProductSell";

const ProductList = () => {
  TabTitle("Product Sell - Kato Haircut");
  // Modal
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProductModal = () => setOpenAddProduct(false);
  const openAddProductModal = () => setOpenAddProduct(true);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const closeEditProductModal = () => {
    setOpenEditProduct(false);
    setImageEdit();
  };
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const closeDeleteProductModal = () => setOpenDeleteProduct(false);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [revenue, setRevenue] = useState();
  const [commission, setCommission] = useState();
  const [profit, setProfit] = useState();
  const [totalResult, setTotalResult] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Search
  const [searchType, setSearchType] = useState("Product ID");
  const [searchValue, setSearchValue] = useState("");
  const [currentCategory, setCurrentCategory] = useState("All");
  const [currentIdCategory, setCurrentIdCategory] = useState(null);
  // Handle Create
  const [dataCategory, setDataCategory] = useState([]);
  const [category, setCategory] = useState();
  const [name, setName] = useState("");
  const [price, setPrice] = useState();
  const [image, setImage] = useState();
  // Handle Edit
  const [idEdit, setIdEdit] = useState("");
  const [codeEdit, setCodeEdit] = useState("");
  const [categoryEdit, setCategoryEdit] = useState();
  const [nameEdit, setNameEdit] = useState("");
  const [priceEdit, setPriceEdit] = useState();
  const [imageEdit, setImageEdit] = useState();
  // Handle Delete
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const fetchDataCategory = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/categories/all`,
        Session()
      );
      setDataCategory(data.data);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    // fetchDataCategory();
    // fetchAllCategoryProduct();
    fetchProductSell();
  }, []);

  // const showTablePage = (page) => {
  //   setCurrentTablePage(page);
  //   if (currentCategory === "All") {
  //     fetchAllCategoryProduct(searchValue, page);
  //   } else {
  //     fetchSpecificCategoryProduct(currentIdCategory, searchValue, page);
  //   }
  // };

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchProductSell(page, searchValue, dateStart, dateEnd);
  };

  const showSearchedTablePage = (val) => {
    // setSearchValue(searchValue);
    // setCurrentTablePage(1);
    // if (currentCategory === "All") {
    //   fetchAllCategoryProduct(searchValue, 1);
    // } else {
    //   fetchSpecificCategoryProduct(currentIdCategory, searchValue, 1);
    // }

    setSearchValue(val);
    setCurrentTablePage(1);
    // setDateStart("");
    // setDateEnd("");
    fetchProductSell(1, val, dateStart, dateEnd);
  };

  const fetchSpecificCategoryProduct = async (id, name, page) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/categories/products-by-category/${id}${
          name !== "" && name !== undefined
            ? page !== "" && page !== undefined
              ? `?name=${name}&page=${page}`
              : `?name=${name}`
            : page !== "" && page !== undefined
            ? `?page=${page}`
            : ``
        }`,
        Session()
      );
      setTableData(data.data);
      setTotalResult(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchAllCategoryProduct = async (name, page) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products${
          name !== "" && name !== undefined
            ? page !== "" && page !== undefined
              ? `?name=${name}&page=${page}`
              : `?name=${name}`
            : page !== "" && page !== undefined
            ? `?page=${page}`
            : ``
        }`,
        Session()
      );
      setTableData(data.data);
      setTotalResult(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchProductSell = async (
    page = currentTablePage,
    search = "",
    dateStart = "",
    dateEnd = ""
  ) => {
    try {
      let url = `${API_URL}orders/transaction-items?`;
  
      if (search !== "") {
        if (searchType === "Product ID") {
          url += `searchProductId=${search}`;
        } else if (searchType === "Product Name") {
          url += `searchProductName=${search}`;
        }
      }
  
      if (dateStart !== "" && dateEnd !== "") {
        if (search !== "") {
          url += "&";
        }
        url += `from=${dateStart}&to=${dateEnd}`;
      }
  
      if (page !== "" && page !== undefined) {
        if (search !== "" || dateStart !== "" || dateEnd !== "") {
          url += "&";
        }
        url += `page=${page}`;
      }
      // console.log(url); 
      const { data } = await axios.get(url, Session());
      console.log(data.data); // Log the server response
      setTableData(data.data);
      setTotalResult(data.meta.total);
      setTableCount(data.meta.total);
      setRevenue(data.meta.total_revenue);
      setCommission(data.meta.total_commission);
      setProfit(data.meta.total_profit);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      console.error("Error fetching data:", err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  }; 

  const exportAll = async () => {
    try {
      // Get data from the API
      let { data } = await axios.get(
        `${API_URL}orders/transaction-items${
          searchValue !== "" && searchValue !== undefined
            ? searchType === "Product ID"
              ? `?searchProductId=${searchValue}&paginate=false`
              : searchType === "Product Name"
              ? `?searchProductName=${searchValue}&paginate=false`
              : `?paginate=false`
            : dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
              : `?paginate=false`
            : `?paginate=false`
        }`,
        Session()
      );

      let temp = data.data.map((data, index) => [
        index + 1,
        data.datetime,
        data.transaction_code,
        data.product_code,
        data.category_name,
        data.product_name,
        data.price,
        data.total_fee,
        data.price_after_discount,
      ]);
      const headings = [
        [
          "No",
          "Date",
          "Transaction ID",
          "Product ID",
          "Category",
          "Product Name",
          "Price",
          "Commission",
          "Total",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(temp);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, temp, {origin: "A4", skipHeader: true});
      utils.book_append_sheet(wb, ws, "Report Data");
      writeFileXLSX(wb, "Report Product Sell Data.xlsx");
    } catch (error) {
      console.error("Error exporting data:", error);
    }

    
  };

  const printAll = async () => {
    let {data} = await axios.get(
      `${API_URL}orders/transaction-items${
        dateStart !== "" && dateStart !== undefined
          ? dateEnd !== "" && dateEnd !== undefined
            ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
            : `?paginate=false`
          : `?paginate=false`
      }`,
      Session()
    );
    let temp = data.data.map((data, index) => [
      index + 1,
      data.datetime,
      data.transaction_code,
      data.product_code,
      data.category_name,
      data.product_name,
      data.price,
      data.total_fee,
      data.price_after_discount,
    ]);
    PrintReport(
      false,
      `Product Sell Report`,
      "PRODUCT SELL REPORT",
      ["Total Revenue", "Total Commission", "Total Profit"],
      [data.meta.total_revenue, data.meta.total_commission ,data.meta.total_profit],
      [
        "No",
        "Date",
        "Transaction ID",
        "Product ID",
        "Category",
        "Product Name",
        "Price",
        "Commission",
        "Total",
      ],
      temp
    );
  };

  const closeAll = () => {};

  const handleChangeCategory = (val) => {
    setCurrentIdCategory(val);
    setCurrentTablePage(1);
    val !== "All"
      ? fetchSpecificCategoryProduct(val, searchValue, 1)
      : fetchAllCategoryProduct(searchValue, 1);
  };

  const prepareEnterDateStart = (val) => {
    setDateStart(val);
    // setCurrentTablePage(1);
    // setSearchValue("");
    fetchProductSell(1, searchValue, val, dateEnd);
  };

  const prepareEnterDateEnd = (val) => {
    setDateEnd(val);
    // setCurrentTablePage(1);
    // setSearchValue("");
    fetchProductSell(1, searchValue, dateStart, val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("category_id", category);
    formData.append("name", name);
    formData.append("price", price);
    try {
      axios.post(
        `${API_URL}products/create`,
        formData,
        Session()
      );
      setImage("");
      setName("");
      setCategory("");
      setPrice("");
      fetchAllCategoryProduct();
      setErrorMsg(
        "Succesfully added product, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed add data, check your input again!");
      }
      setOpenAlert(true);
    }
  };

  const prepareEdit = (val) => {
    setPriceEdit(price);
    setIdEdit(val.id);
    setCodeEdit(val.code);
    setNameEdit(val.name);
    setCategoryEdit(val.category);
    setImageEdit(val.image);
    getEditData(val);
    setOpenEditProduct(true);
  };

  const getEditData = async (val) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/${val.id}}`,
        Session()
      );
      setCodeEdit(data.data.code);
      setNameEdit(data.data.name);
      setCategoryEdit(data.data.category);
      setPriceEdit(data.data.price_unformat);
      setImageEdit(data.data.image);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      let i;
      let category_id = 0;
      for (i in dataCategory) {
        if (dataCategory[i].name === categoryEdit) {
          category_id = dataCategory[i].id;
        }
      }

      let formData = new FormData();
      formData.append("code", codeEdit);
      formData.append("category_id", category_id);
      formData.append("name", nameEdit);
      formData.append("price", priceEdit);
      formData.append("image", imageEdit);
      formData.append("_method", "PUT");

      await axios.post(
        `${API_URL}products/update/${idEdit}`,
        formData,
        Session()
      );
      fetchAllCategoryProduct();
      setErrorMsg(
        "Succesfully edited product data, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed update data");
      }
      setOpenAlert(true);
    }
  };

  const prepareDelete = (id) => {
    setIdDelete(id);
    getDeleteData(id);
    setOpenDeleteProduct(true);
  };

  const getDeleteData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/${id}}`,
        Session()
      );
      setNameDelete(data.data.name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}products/delete/${idDelete}`,
        Session()
      );
      fetchDataCategory();
      fetchAllCategoryProduct();
      setErrorMsg("Succesfully deleted product");
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed delete data");
      }
      setOpenAlert(true);
    }
  };

  return (
    <div className="flex flex-col h-full font-noto-sans">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <div className="w-full flex flex-col md:flex-row grow overflow-auto scrollbar-shown">
        <div className="basis-full md:basis-2/2 lg:basis-6/6">
          <div className="bg-white relative rounded-lg overflow-hidden flex h-full flex-col p-3">
            <div className="flex flex-col xl:flex-row xl:gap-2 border-b pt-2 my-2">
              <div className="flex flex-row gap-2">
                <div className="text-black">
                  <DropdownSelect
                    header={
                      searchType !== "" && searchType !== undefined
                        ? searchType
                        : "Select Comparison..."
                    }
                    showExpandIcon={true}
                    headerClass="px-2 py-2 border rounded-lg flex flex-row items-center gap-0.5 whitespace-nowrap"
                  >
                    <DropdownOption
                      target={searchType}
                      setTarget={setSearchType}
                      value={"Product ID"}
                    />
                    <DropdownOption
                      target={searchType}
                      setTarget={setSearchType}
                      value={"Product Name"}
                    />
                  </DropdownSelect>
                </div>
                <Search
                    textColor={"text-black"}
                    bgColor={"bg-white"}
                    placeholder={`Search by ${
                        searchType === "Product ID"
                        ? "product id"
                        : "product name"
                    }...`}
                    searchValue={searchValue}
                    setSearchValue={showSearchedTablePage}
                />
              </div>
              <FilterByDate
                dateStart={dateStart}
                setDateStart={prepareEnterDateStart}
                dateEnd={dateEnd}
                setDateEnd={prepareEnterDateEnd}
              />
              <DropdownMenuExport
                export={exportAll}
                print={printAll}
                close={closeAll}
              />
            </div>
            <div className="flex flex-row my-2 justify-between">
              <div className="flex flex-col gap-y-5 sm:flex-row sm:gap-x-5">
                <div className="flex flex-col">
                  <h1 className="text-black">Total Revenue Product Sell</h1>
                  <h1 className="text-green-500 font-semibold text-2xl">
                    Rp{revenue}
                  </h1>
                </div>
                <div className="flex flex-col">
                  <h1 className="text-black">Total Commission Product Sell</h1>
                  <h1 className="text-green-500 font-semibold text-2xl">
                    Rp{commission}
                  </h1>
                </div>
                <div className="flex flex-col">
                  <h1 className="text-black">Total Profit Product Sell</h1>
                  <h1 className="text-green-500 font-semibold text-2xl">
                    Rp{profit}
                  </h1>
                </div>
              </div>
            </div>

            <p className="w-full text-black mb-3 mt-2">Total All Order : {tableCount} </p>

            {tableData[0] ? (
              <>
                <div
                  id="printArea"
                  className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col my-2"
                >
                  <TableProductSell
                    tableData={tableData}
                    currentPage={currentTablePage}
                  />
                </div>
                <Pagination
                  maxPage={Math.ceil(tableCount / itemsPerPage)}
                  currentPage={currentTablePage}
                  showTablePage={showTablePage}
                />
              </>
            ) : (
              <p className="w-full text-black">No result</p>
            )}
          </div>
        </div>
      </div>
    </div>

    // <div className="w-full flex flex-col h-full overflow-auto scrollbar-shown">
    //     <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
    //     <ModalCreateProduct
    //         show={openAddProduct}
    //         close={closeAddProductModal}
    //         dataCategory={dataCategory}
    //         categoryValue={category}
    //         setCategoryValue={setCategory}
    //         nameValue={name}
    //         setNameValue={setName}
    //         priceValue={price}
    //         setPriceValue={setPrice}
    //         imageValue={image}
    //         setImageValue={setImage}
    //         submit={handleSubmit}
    //     />
    //     <ModalEditProduct
    //         show={openEditProduct}
    //         close={closeEditProductModal}
    //         dataCategory={dataCategory}
    //         codeEditValue={codeEdit}
    //         setCodeEditValue={setCodeEdit}
    //         categoryEditValue={categoryEdit}
    //         setCategoryEditValue={setCategoryEdit}
    //         nameEditValue={nameEdit}
    //         setNameEditValue={setNameEdit}
    //         priceEditValue={priceEdit}
    //         setPriceEditValue={setPriceEdit}
    //         imageEditValue={imageEdit}
    //         setImageEditValue={setImageEdit}
    //         submit={handleEdit}
    //     />
    //     <ModalDeleteProduct
    //         show={openDeleteProduct}
    //         close={closeDeleteProductModal}
    //         nameDeleteValue={nameDelete}
    //         submit={handleDelete}
    //     />
    //     <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col">
    //         <div className="basis-full md:basis-2/2 lg:basis-6/6">
    //             <div className="bg-white relative rounded-lg overflow-hidden flex h-full flex-col">                   
    //                 <div className="flex flex-col xl:flex-row xl:gap-2 border-b my-2">
    //                     <div className="flex flex-row gap-2">
    //                     <div className="text-black">
    //                     <DropdownSelect
    //                         header={
    //                         searchType !== "" && searchType !== undefined
    //                             ? searchType
    //                             : "Select Comparison..."
    //                         }
    //                         showExpandIcon={true}
    //                         headerClass="px-2 py-2 border rounded-lg flex flex-row items-center gap-0.5 whitespace-nowrap"
    //                     >
    //                         <DropdownOption
    //                             target={searchType}
    //                             setTarget={setSearchType}
    //                             value={"Category"}
    //                         />
    //                         <DropdownOption
    //                             target={searchType}
    //                             setTarget={setSearchType}
    //                             value={"Product Name"}
    //                         />
    //                     </DropdownSelect>
    //                     </div>
    //                     <Search
    //                         textColor={"text-black"}
    //                         bgColor={"bg-white"}
    //                         placeholder={`Search by ${
    //                             searchType === "Category"
    //                             ? "category"
    //                             : "product name"
    //                         }...`}
    //                         searchValue={searchValue}
    //                         setSearchValue={showSearchedTablePage}
    //                     />
    //                     </div>
    //                     <FilterByDate
    //                         dateStart={dateStart}
    //                         setDateStart={prepareEnterDateStart}
    //                         dateEnd={dateEnd}
    //                         setDateEnd={prepareEnterDateEnd}
    //                     />
    //                 </div>
    //                 <div className="flex flex-row my-2 justify-between">
    //                     <div className="flex flex-col gap-y-5 sm:flex-row sm:gap-x-5">
    //                         <div className="flex flex-col">
    //                             <h1 className="text-black">Total Revenue Transaction</h1>
    //                             <h1 className="text-green-500 font-semibold text-2xl">
    //                             Rp {revenue}
    //                             </h1>
    //                         </div>
    //                         <div className="flex flex-col">
    //                             <h1 className="text-black">Total Profit</h1>
    //                             <h1 className="text-green-500 font-semibold text-2xl">
    //                             Rp {profit}
    //                             </h1>
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <p className="w-full text-black mb-4 mt-4">Total All Order : {tableCount}</p>
            
    //                 {tableData[0] ? (
    //                 <>
    //                   <div
    //                     className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col my-2"
    //                   >
    //                     <TableProductSell
    //                       tableData={tableData}
    //                       currentPage={currentTablePage}
    //                     />
    //                   </div>
    //                   <Pagination
    //                     maxPage={Math.ceil(tableCount / itemsPerPage)}
    //                     currentPage={currentTablePage}
    //                     showTablePage={showTablePage}
    //                   />
    //                 </>
    //               ) : (
    //                 <p className="w-full text-black">No result</p>
    //               )}

    //                 {/* {totalResult ? (
    //                 <>
    //                     <div className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col my-2">
    //                       <TableProductSell
    //                         tableData={tableData}
    //                         currentPage={currentTablePage}
    //                       />
    //                     </div>
    //                       <Pagination
    //                           maxPage={Math.ceil(totalResult / itemsPerPage)}
    //                           currentPage={currentTablePage}
    //                           showTablePage={showTablePage}
    //                       />
    //                 </>
    //                 ) : (
    //                     <p className="w-full text-black">Waiting for Data</p>
    //                 )} */}
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
};

export default ProductList;
