import {Menu, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {MdExpandLess, MdExpandMore} from "react-icons/md";

const DropdownSelect = ({
  header,
  iconLeft,
  iconRight,
  showExpandIcon,
  headerClass = "px-2 py-1 border rounded-lg flex flex-row items-center gap-0.5 whitespace-nowrap",
  headerClassOpen = "bg-white text-black ",
  headerClassClose = "bg-transparent ",
  extraClassContainer = "",
  ...props
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left z-10">
      {({open}) => (
        <>
          <div>
            <Menu.Button
              className={`${
                open ? headerClassOpen : headerClassClose
              }${headerClass}`}
            >
              {iconLeft}
              {header}
              {showExpandIcon ? (
                open ? (
                  <>
                    <MdExpandLess className="text-2xl block relative z-20" />
                  </>
                ) : (
                  <>
                    <MdExpandMore className="text-2xl block relative z-20" />
                  </>
                )
              ) : (
                iconRight
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items
              className={
                extraClassContainer +
                "absolute left-0 mt-2 w-56 origin-top-left rounded-md px-0 py-1 bg-white dropdown-box-shadow focus:outline-none flex flex-col"
              }
            >
              {props.children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropdownSelect;
