// source https://codesandbox.io/s/practical-hawking-xi9w9i?file=/index.html

const printInvoice = (
  isPopUp = false,
  title,
  id,
  status,
  data,
  subTotal = 0,
  discountValue = 0,
  grandTotal = 0,
  date
) => {
  let a = window.open("", "", `${isPopUp ? "popup=true" : "popup=false"}`);
  a.document.write(
    `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><meta name="viewport" content="width=device-width, initial-scale=1.0" /><meta http-equiv="X-UA-Compatible" content="ie=edge" /><title>${title}</title>`
  );
  a.document.write(
    `<style> * { margin: 0; padding: 0; box-sizing: border-box; font-family: sans-serif; font-size: 8px; color: #000000; } .font-weight600 { font-weight: 600; } .font-size-8 { font-size: 8px; } .font-size-10 { font-size: 10px; } .font-size-14 { font-size: 14px; } .font-size-16 { font-size: 16px; } .page { width: 155px; max-width: 155px; } .flex-row { display: flex; flex-direction: row; } .flex-col { display: flex; flex-direction: column; } .items-center { align-items: center; } .content-center { justify-content: center; } .content-between { justify-content: space-between; } .text-left { text-align: left; } .text-right { text-align: right; } .text-center { text-align: center; } .gap-small { gap: 8px; } .gap-medium { gap: 16px; } .gap-large { gap: 24px; } .padding-small { padding: 8px; } .padding-medium { padding: 16px; } .padding-left-large { padding-left: 36px; } .border-dot { border-top: 1px dashed #000; } body { -webkit-print-color-adjust: exact; color-adjust: exact; } @media print { .hidden-print, .hidden-print * { display: none !important; } } </style>`
  );
  a.document.write("</head><body>");
  a.document.write(
    `<div class="page padding-medium flex-col gap-small"> 
      <header class="flex-col gap-small"> 
        <h2 class="text-center font-size-14">Katto Hair Design</h2> 
          <div> 
            <p class="text-center font-size-8"> Jl. Langensari, Klitren, Kec. Gondokusuman, Kota Yogyakarta, DIY 55221 </p>
            <p class="text-center font-size-8"> 0857-2397-4265 </p> 
          </div> 
      </header> <main class="flex-col gap-small"> 
      <div class="flex-row content-between"> 
        <div class="flex-col gap-small"> 
          <h4 class="font-size-8">ID Transaction</h4> 
            <p class="font-size-8">#${id}</p> 
        </div> 
        <div> 
          <h4 class="font-size-8">${status}</h4> 
        </div> 
      </div> 
      <div class="flex-col gap-small"> <h4>Service</h4>`
  );
  // list services
  data?.forEach((item, index) => {
    a.document.write(
      `<dl class="flex-row content-between"> <div class="flex-row gap-small"> <div>${
        index + 1
      }</div> <dt class="flex-col"> <p>${
        item.product_name
      }</p> </dt> </div> <dd>${item.price}</dd> </dl>`
    );
  });
  a.document.write("</div>");
  // list payment detail
  a.document.write(
    `<dl class="flex-col gap-small"> <div class="border-dot"></div> <div class="flex-row content-between"> <dt>Sub Total</dt> <dd>${subTotal}</dd> </div> <div class="flex-row content-between"> <dt>Discount</dt> <dd>${discountValue}</dd> </div> <div class="border-dot"></div> <div class="flex-row content-between"> <dt>Grand Total</dt> <dd>${grandTotal}</dd> </div> </dl>`
  );
  a.document.write("</main>");
  a.document.write(
    `<footer> <div class="flex-row content-center"> <div class="flex-col"> <h4 class="text-center">Terima Kasih</h4> <p>${date}</p> </div> </div> </footer>`
  );
  a.document.write("</div>");
  a.document.write(
    `<div class="page flex-row content-center hidden-print"> <button onclick="window.print()">Print</button> </div>`
  );
  a.document.write("</body></html>");
  a.document.close();
  a.print();
};

export default printInvoice;
