import TabTitle from "../utils/GeneralFunction";
import {useState} from "react";
import Search from "../components/Search";
import CashierCategoryList from "../components/CashierCategoryList";
import CashierProductList from "../components/CashierProductList";
import axios from "axios";
import {useEffect} from "react";
import ModalSelectProductCashier from "../components/ModalSelectProductCashier";
import CashierRightPanelTop from "../components/CashierRightPanelTop";
import CashierDataInput from "../components/CashierDataInput";
import {Session, getId} from "../Session";
import ModalCreateCustomerCashier from "../components/ModalCreateCustomerCashier";
import ModalEditProductCashier from "../components/ModalEditProductCashier";
import {useNavigate} from "react-router-dom";
import ModalAlertCashier from "../components/ModalAlertCashier";
import { API_URL } from '../utils/api';

const getLocalStorageData = JSON.parse(localStorage.getItem("cart") || "[]");
const getTaskStorageData = JSON.parse(localStorage.getItem("task") || "[]");

const CashierInput = () => {
  let navigate = useNavigate();
  TabTitle("Cashier - Kato Haircut");
  // Modal
  const [openSelectProduct, setOpenSelectProduct] = useState(false);
  const closeSelectProductModal = () => {
    setOpenSelectProduct(false);
    localStorage.removeItem("task");
    setTask([]);
  };
  const openSelectProductModal = () => setOpenSelectProduct(true);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const closeEditProductModal = () => {
    localStorage.removeItem("task");
    setTaskEdit([]);
    setOpenEditProduct(false);
  };
  const openEditProductModal = () => setOpenEditProduct(true);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const closeAddCustomerModal = () => setOpenAddCustomer(false);
  const openAddCustomerModal = () => setOpenAddCustomer(true);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setMessage("");
    setActiveCart(null);
  };
  const openAlertModal = () => setOpenAlert(true);
  const [activeCart, setActiveCart] = useState(null);
  // Search
  const [searchValue, setSearchValue] = useState("");
  const [dataCategory, setDataCategory] = useState([]);
  const [dataEmployee, setDataEmployee] = useState([]);
  const [dataActivity, setDataActivity] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("All");
  const [idCurrentCategory, setIdCurrentCategory] = useState(null);
  const [dataProduct, setDataProduct] = useState([]);
  const [totalResult, setTotalResult] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // select
  const [selectProduct, setSelectProduct] = useState();
  const [stylist, setStylist] = useState("");
  const [discountType, setDiscountType] = useState("none");
  const [discountValue, setDiscountValue] = useState("");
  const [task, setTask] = useState(getTaskStorageData);
  const [taskDump, setTaskDump] = useState([]);
  const [cart, setCart] = useState(getLocalStorageData);
  const [totalPriceCart, setTotalPriceCart] = useState(0);
  // const [totalDiscountCart, setTotalDiscountCart] = useState(0);
  // edit
  const [productIndex, setProductIndex] = useState(null);
  const [editProduct, setEditProduct] = useState({});
  const [stylistEdit, setStylistEdit] = useState("");
  const [taskEdit, setTaskEdit] = useState([]);
  const [discountTypeEdit, setDiscountTypeEdit] = useState("none");
  const [discountValueEdit, setDiscountValueEdit] = useState("");
  // old customer
  const [categoryCustomer, setCategoryCustomer] = useState("new");
  const [activeCustomerData, setActiveCustomerData] = useState();
  // new customer
  const [newCustomerBirthday, setNewCustomerBirthday] = useState("");
  const [newCustomerName, setNewCustomerName] = useState("");
  const [newCustomerPhone, setNewCustomerPhone] = useState("");
  const [newCustomerMembership, setNewCustomerMembership] = useState("");
  // old and new customer
  const [customerId, setCustomerId] = useState("");
  const [dataCustomer, setDataCustomer] = useState([]);

  const [message, setMessage] = useState("");

  const fetchCategoryData = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/categories/all`,
        Session()
      );
      setDataCategory(data.data);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchSpecificCategoryProduct = async (id, name, page) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/categories/products-by-category/${id}${
          name !== "" && name !== undefined
            ? page !== "" && page !== undefined
              ? `?name=${name}&page=${page}`
              : `?name=${name}`
            : page !== "" && page !== undefined
            ? `?page=${page}`
            : ``
        }`,
        Session()
      );
      setDataProduct(data.data);
      setTotalResult(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchAllCategoryProduct = async (name, page) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products${
          name !== "" && name !== undefined
            ? page !== "" && page !== undefined
              ? `?name=${name}&page=${page}`
              : `?name=${name}`
            : page !== "" && page !== undefined
            ? `?page=${page}`
            : ``
        }`,
        Session()
      );
      setDataProduct(data.data);
      setTotalResult(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const prepareSearch = (val) => {
    setSearchValue(val);
    setCurrentPage(1);
    if (currentCategory === "All") {
      fetchAllCategoryProduct(val, 1);
    } else {
      fetchSpecificCategoryProduct(idCurrentCategory, val, 1);
    }
  };

  const fetchDataCustomer = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}customers?paginate=false`,
        Session()
      );
      setDataCustomer(data.data);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchOneDataCustomer = async (val) => {
    try {
      const {data} = await axios.get(
        `${API_URL}customers/${val}`,
        Session()
      );
      setActiveCustomerData(data.data);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchDataEmployee = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees?paginate=false`,
        Session()
      );
      setDataEmployee(data.data);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const createNewCustomer = async (e) => {
    // e.preventDefault();
    try {
      await axios
        .post(
          `${API_URL}customers/create`,
          {
            birthday: newCustomerBirthday,
            phone: newCustomerPhone,
            name: newCustomerName,
            membership: newCustomerMembership,
          },
          Session()
        )
        .then((response) => {
          setActiveCustomerData(response.data.data);
          setCustomerId(response.data.data.id);
        });
    } catch (err) {
      closeAddCustomerModal();
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleAddCustomer = (val) => {
    val.preventDefault();
    if (categoryCustomer === "new") {
      createNewCustomer();
    } else if (categoryCustomer === "old") {
      fetchOneDataCustomer(customerId);
    }
  };

  const handleChangeCategory = (val) => {
    setCurrentPage(1);
    setIdCurrentCategory(val);
    val !== "All"
      ? fetchSpecificCategoryProduct(val, searchValue, 1)
      : fetchAllCategoryProduct(searchValue, 1);
  };

  const getDetailProduct = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/${id}`,
        Session()
      );
      setSelectProduct(data.data);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchDataActivity = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}activities?paginate=false`,
        Session()
      );
      setDataActivity(data.data);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data. Try Again!");
      }
      setOpenAlert(true);
    }
  };

  const handleSelectProduct = (id) => {
    if (!customerId) {
      setMessage("Please, add your customer first before you add item");
      openAlertModal();
    } else {
      getDetailProduct(id);
      openSelectProductModal();
    }
  };

  const btnAddTask = (e) => {
    e.preventDefault();
    const newList = [...task, {activity_id: "", employee_id: ""}];
    setTask(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const btnDeleteItem = (e, index) => {
    e.preventDefault();
    const newList = [...task];
    newList.splice(index, 1);
    setTask(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const inputUpdateActivity = (e, index, val) => {
    e.preventDefault();
    const newList = [...task];
    newList[index].activity_id = val;
    setTask(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const inputUpdateEmployee = (e, index, val) => {
    e.preventDefault();
    const newList = [...task];
    newList[index].employee_id = val;
    setTask(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const getFirstEmployee = async () => {
    let stylist = JSON.parse(localStorage.getItem("task"));
    let firstStylist = parseInt(stylist[0].employee_id);
    for (let i = 0; i < dataEmployee.length; i++) {
      if (dataEmployee[i].id === firstStylist) {
        setStylist(dataEmployee[i].name);
      }
    }
  };

  const dumpTaskData = async () => {
    let taskDump = JSON.parse(localStorage.getItem("task"));
    setTaskDump(taskDump);
  };

  const prepareAddToCart = async (e) => {
    e.preventDefault();
    addToCart(stylist);
  };

  const addToCart = (stylistName) => {
    try {
      setCart((data) => [
        ...data,
        {
          first_stylist_name: stylistName,
          product_id: selectProduct.id,
          product_name: selectProduct.name,
          product_price: selectProduct.price,
          product_price_unformat: selectProduct.price_unformat,
          service_discount_type: discountType,
          service_discount_amount: discountValue,
          activities: taskDump,
        },
      ]);
      setStylist("");
      setTask([]);
      setTaskDump([]);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const prepareEditData = (val) => {
    setProductIndex(val);
    setEditProduct(cart[val]);
    setDiscountTypeEdit(cart[val].service_discount_type);
    if (isNaN(cart[val].service_discount_amount)) {
      setDiscountValueEdit("");
    } else {
      setDiscountValueEdit(cart[val].service_discount_amount);
    }
    setTaskEdit(cart[val].activities);
    let newList = cart[val].activities;
    localStorage.setItem("task", JSON.stringify(newList));
    openEditProductModal();
  };

  const btnEditAddTask = (e) => {
    e.preventDefault();
    const newList = [...taskEdit, {activity_id: "", employee_id: ""}];
    setTaskEdit(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const btnEditDeleteItem = (e, index) => {
    e.preventDefault();
    const newList = [...taskEdit];
    newList.splice(index, 1);
    setTaskEdit(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const inputEditUpdateActivity = (e, index, val) => {
    e.preventDefault();
    const newList = [...taskEdit];
    newList[index].activity_id = val;
    setTaskEdit(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const inputEditUpdateEmployee = (e, index, val) => {
    e.preventDefault();
    const newList = [...taskEdit];
    newList[index].employee_id = val;
    setTaskEdit(newList);
    localStorage.setItem("task", JSON.stringify(newList));
  };

  const handleEditCart = async (e) => {
    e.preventDefault();
    setToCartEdit(stylist);
  };

  const setToCartEdit = (stylistName) => {
    let data = JSON.parse(localStorage.getItem("cart"));
    data[productIndex] = {
      first_stylist_name: stylistName,
      product_id: data[productIndex].product_id,
      product_name: data[productIndex].product_name,
      product_price: data[productIndex].product_price,
      product_price_unformat: data[productIndex].product_price_unformat,
      service_discount_type: discountTypeEdit,
      service_discount_amount: discountValueEdit,
      activities: taskDump,
    };
    localStorage.setItem("cart", JSON.stringify(data));
    setCart(data);
  };

  const prepareDeleteData = (val) => {
    let data = JSON.parse(localStorage.getItem("cart"));
    data.splice(val, 1);
    localStorage.setItem("cart", JSON.stringify(data));
    setCart(data);
  };

  const postCart = async () => {
    try {
      await axios
        .post(
          `${API_URL}cashier/create`,
          {
            user_id: getId(),
            customer_id: parseInt(customerId),
            discount_type: "none",
            discount_amount: "",
            service_items: JSON.parse(localStorage.getItem("cart")),
          },
          Session()
        )
        .then(() => clearCart());
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't post data");
      }
      setOpenAlert(true);
    }
  };

  const prepareConfirmPayment = () => {
    postCart();
    navigate("/cashier/confirmation");
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  const checkActiveCart = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}cashier/show`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: getId(),
          },
        }
      );
      setActiveCart(data.data.id);
      if (data) {
        setMessage(
          "You have unfinished transaction, want to continue the process?"
        );
        setOpenAlert(true);
      }
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else if (err.response?.status === 500) {
        return 0;
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleDeletePayment = async () => {
    try {
      await axios.delete(
        `${API_URL}cashier/delete/` + activeCart,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            user_id: getId(),
          },
        }
      );
      closeAlertModal();
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't post data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    checkActiveCart();
    fetchCategoryData();
    fetchAllCategoryProduct(searchValue, currentPage);
    fetchDataEmployee();
    fetchDataCustomer();
    fetchDataActivity();
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("cart"));
    let totalPrice = 0;
    data.forEach((val) => {
      totalPrice += val.product_price_unformat;
    });
    setTotalPriceCart(totalPrice);
  }, [cart]);

  const showPage = (page) => {
    setCurrentPage(page);
    if (currentCategory === "All") {
      fetchAllCategoryProduct(searchValue, page);
    } else {
      fetchSpecificCategoryProduct(idCurrentCategory, searchValue, page);
    }
  };

  return (
    <div className="flex flex-col h-full font-nunito-sans">
      <ModalAlertCashier
        show={openAlert}
        close={closeAlertModal}
        message={message}
        handleDeletePayment={handleDeletePayment}
      />
      <ModalSelectProductCashier
        show={openSelectProduct}
        close={closeSelectProductModal}
        dataProduct={selectProduct}
        dataEmployee={dataEmployee}
        dataActivity={dataActivity}
        task={task}
        setTask={setTask}
        updateActivity={inputUpdateActivity}
        updateEmployee={inputUpdateEmployee}
        deleteItem={btnDeleteItem}
        btnAddTask={btnAddTask}
        discountType={discountType}
        setDiscountType={setDiscountType}
        setDiscountValue={setDiscountValue}
        getFirstEmployee={getFirstEmployee}
        dumpTaskData={dumpTaskData}
        submit={prepareAddToCart}
      />
      <ModalEditProductCashier
        show={openEditProduct}
        close={closeEditProductModal}
        dataProduct={editProduct}
        dataEmployee={dataEmployee}
        dataActivity={dataActivity}
        // stylistValue={stylistEdit}
        task={taskEdit}
        setTask={setTaskEdit}
        updateActivity={inputEditUpdateActivity}
        updateEmployee={inputEditUpdateEmployee}
        deleteItem={btnEditDeleteItem}
        btnAddTask={btnEditAddTask}
        discountType={discountTypeEdit}
        discountValue={discountValueEdit}
        setStlylistValue={setStylistEdit}
        setDiscountType={setDiscountTypeEdit}
        setDiscountValue={setDiscountValueEdit}
        getFirstEmployee={getFirstEmployee}
        dumpTaskData={dumpTaskData}
        submit={handleEditCart}
      />
      <ModalCreateCustomerCashier
        show={openAddCustomer}
        close={closeAddCustomerModal}
        dataCustomer={dataCustomer}
        categoryCustomer={categoryCustomer}
        customerId={customerId}
        setCustomerId={setCustomerId}
        setCategoryCustomer={setCategoryCustomer}
        // new customer
        birthdayValue={newCustomerBirthday}
        setBirthdayValue={setNewCustomerBirthday}
        nameValue={newCustomerName}
        setNameValue={setNewCustomerName}
        phoneValue={newCustomerPhone}
        setPhoneValue={setNewCustomerPhone}
        membershipValue={newCustomerMembership}
        setMembershipValue={setNewCustomerMembership}
        submit={handleAddCustomer}
      />

      <div className="relative flex flex-col md:flex-row grow overflow-auto md:overflow-hidden scrollbar-shown">
        <div className="flex flex-col mb-2 md:mb-0 basis-full md:relative md:overflow-x-auto md:basis-1/2 lg:basis-4/6">
          <Search
            textColor={"text-white"}
            bgColor={"bg-black"}
            placeholder={"Search by product name..."}
            searchValue={searchValue}
            setSearchValue={prepareSearch}
          />
          <CashierCategoryList
            dataCategory={dataCategory}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
            handleChangeCategory={handleChangeCategory}
          />
          <CashierProductList
            dataProduct={dataProduct}
            selectProduct={handleSelectProduct}
            totalProductResult={totalResult}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            showPage={showPage}
          />
        </div>
        <div className="flex flex-col basis-full xl:ml-2 md:basis-1/2 lg:basis-2/6">
          <div className="bg-white flex flex-col rounded-tl-lg rounded-tr-lg md:rounded-tr-none h-full relative">
            <CashierRightPanelTop
              addCustomer={openAddCustomerModal}
              activeCustomerData={activeCustomerData}
              clearCustomer={() => {
                setCustomerId("");
                setActiveCustomerData("");
                clearCart();
              }}
              clear={clearCart}
            />
            <CashierDataInput
              dataCashier={cart}
              activeCustomerData={activeCustomerData}
              deleteData={prepareDeleteData}
              editData={prepareEditData}
              prepareConfirmPayment={prepareConfirmPayment}
              totalPrice={totalPriceCart}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashierInput;
