import {MdModeEditOutline, MdDeleteOutline} from "react-icons/md";

const TableAdmin = ({tableData, editRow, deleteRow, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }

  const capitalizeEachWord = (sentence) => {
    const words = sentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  return (
    <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
      <thead className="sticky top-0">
        <tr className="bg-[#F9F9FC] text-black text-left">
          <th className="py-2 whitespace-nowrap">No</th>
          <th className="py-2 whitespace-nowrap">Phone Number</th>
          <th className="py-2 whitespace-nowrap">Username</th>
          <th className="py-2 whitespace-nowrap">Role</th>
          <th className="py-2 whitespace-nowrap">Action</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 &&
          tableData.map((row) => (
            <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
              <td className="py-4 whitespace-nowrap">{startNumber++}</td>
              <td className="py-2 whitespace-nowrap">{row.phone}</td>
              <td className="py-2 whitespace-nowrap">{row.username}</td>
              <td className="py-2 whitespace-nowrap">
                {capitalizeEachWord(row.role)}
              </td>
              <td className="py-2 whitespace-nowrap">
                <button
                  onClick={() => {
                    editRow(row.id);
                  }}
                >
                  <MdModeEditOutline className="text-red-500 hover:text-red-800" />
                </button>
                <button onClick={() => deleteRow(row.id)}>
                  <MdDeleteOutline className="text-red-500 hover:text-red-800" />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableAdmin;
