import {MdModeEditOutline, MdDeleteOutline} from "react-icons/md";

const TableEmployee = ({
  tableData,
  editRow,
  deleteRow,
  idTable,
  detail,
  currentPage,
}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 15 + 1;
  }

  const capitalizeEachWord = (sentence) => {
    const words = sentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  return (
    <div id={idTable}>
      <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
        <thead className="sticky top-0">
          <tr className="bg-[#F9F9FC] text-black text-left">
            <th className="py-2 text-center whitespace-nowrap">No</th>
            <th className="py-2 text-center whitespace-nowrap">Employee Id</th>
            <th className="py-2 whitespace-nowrap">Name</th>
            <th className="py-2 text-center whitespace-nowrap">Telephone</th>
            <th className="py-2 text-center whitespace-nowrap">Job</th>
            <th className="py-2 text-center whitespace-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 &&
            tableData.map((row) => (
              <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
                <td className="py-4 text-center whitespace-nowrap">{startNumber++}</td>
                <td className="py-2 text-center whitespace-nowrap">{row.code}</td>
                <td className="py-2 whitespace-nowrap">{row.name}</td>
                <td className="py-2 text-center whitespace-nowrap">{row.phone}</td>
                <td className="py-2 text-center whitespace-nowrap">
                  {capitalizeEachWord(row.job_name)}
                </td>
                <td className="py-2 flex justify-center whitespace-nowrap">
                  <button
                    onClick={() => {
                      editRow(row);
                    }}
                  >
                    <MdModeEditOutline className="text-red-500 hover:text-red-800" />
                  </button>
                  <button onClick={() => deleteRow(row.id)}>
                    <MdDeleteOutline className="ml-2 text-red-500 hover:text-red-800" />
                  </button>
                  <button
                    className="px-3 ml-2 py-2 bg-blue-100 hover:bg-blue-400 rounded-lg mr-2"
                    onClick={() => detail(row.id)}
                  >
                    See Detail
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableEmployee;
