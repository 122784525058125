const TableLoglogin = ({tableData, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }

  return (
    <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
      <thead className="sticky top-0">
        <tr className="bg-[#F9F9FC] text-black text-left">
          <th className="py-2 whitespace-nowrap">No</th>
          <th className="py-2 whitespace-nowrap">Date & Time</th>
          <th className="py-2 whitespace-nowrap">Username</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 &&
          tableData.map((row) => (
            <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
              <td className="py-4 whitespace-nowrap">{startNumber++}</td>
              <td className="py-2 whitespace-nowrap">{row.datetime}</td>
              <td className="py-2 whitespace-nowrap">{row.admin_name}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableLoglogin;
