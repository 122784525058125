import React from "react";

const TableEmployeePresence = ({tableData, idTable, detail, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }

  return (
    <div id={idTable}>
      <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
        <thead className="sticky top-0">
          <tr className="bg-[#F9F9FC] text-black text-left">
            <th className="py-2 text-center whitespace-nowrap">No</th>
            <th className="py-2 text-center whitespace-nowrap">Employee Id</th>
            <th className="py-2 text-center whitespace-nowrap">Name</th>
            <th className="py-2 text-center whitespace-nowrap">Total Presence</th>
            <th className="py-2 text-center whitespace-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 &&
            tableData.map((row) => (
              <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
                <td className="py-4 text-center whitespace-nowrap">{startNumber++}</td>
                <td className="py-2 text-center whitespace-nowrap">{row.code}</td>
                <td className="py-2 text-center whitespace-nowrap">{row.name}</td>
                <td className="py-2 text-center whitespace-nowrap">{row.total_presence}</td>
                <td className="py-2 flex justify-center whitespace-nowrap">
                  <button
                    className="px-3 ml-2 py-2 bg-blue-100 hover:bg-blue-400 rounded-lg mr-2"
                    onClick={() => detail(row.id, row.name)}
                  >
                    See Detail
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableEmployeePresence;
