import {MdAdd} from "react-icons/md";
import TabTitle from "../utils/GeneralFunction";
import TableCustomers from "../components/TableCustomers";
import Search from "../components/Search";
import axios from "axios";
import {useEffect, useState} from "react";
import Pagination from "../components/Pagination";
import ModalCreateCustomer from "../components/ModalCreateCustomer";
import ModalEditCustomer from "../components/ModalEditCustomer";
import ModalDeleteCustomer from "../components/ModalDeleteCustomer";
import CustomerDetail from "../components/CustomerDetail";
import Session from "../Session";
import ModalShowDetailOrder from "../components/ModalShowDetailOrder";
import ModalAlert from "../components/ModalAlert";
import DropdownSelect from "../components/DropdownSelect";
import DropdownOption from "../components/DropdownOption";
import {utils, writeFileXLSX} from "xlsx";
import PrintReport from "../utils/PrintReport";
import { API_URL } from '../utils/api';
import Select from "react-select";

const Customers = () => {
  TabTitle("Customers - Kato Haircut");
  // Modal
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const closeAddCustomerModal = () => setOpenAddCustomer(false);
  const openAddCustomerModal = () => setOpenAddCustomer(true);
  const [openEditCustomer, setOpenEditCustomer] = useState(false);
  const closeEditCustomerModal = () => setOpenEditCustomer(false);
  const [openDeleteCustomer, setOpenDeleteCustomer] = useState(false);
  const closeDeleteCustomerModal = () => setOpenDeleteCustomer(false);
  const [openDetailOrder, setOpenDetailOrder] = useState(false);
  const closeDetailOrderModal = () => setOpenDetailOrder(false);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Search
  const [searchType, setSearchType] = useState("Customer Name");
  const [searchValue, setSearchValue] = useState("");
  // Dropdown options
  const months = [
    { value: "01", label: "Januari" },
    { value: "02", label: "Februari" },
    { value: "03", label: "Maret" },
    { value: "04", label: "April" },
    { value: "05", label: "Mei" },
    { value: "06", label: "Juni" },
    { value: "07", label: "Juli" },
    { value: "08", label: "Agustus" },
    { value: "09", label: "September" },
    { value: "10", label: "Oktober" },
    { value: "11", label: "November" },
    { value: "12", label: "Desember" },
    // Add more months here if needed
  ];
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };

  // const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

  // Handle Create
  const [birthday, setBirthday] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [membership, setMembership] = useState("");
  // Handle Edit
  const [idEdit, setIdEdit] = useState("");
  const [codeEdit, setCodeEdit] = useState("");
  const [birthdayEdit, setBirthdayEdit] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [nameEdit, setNameEdit] = useState("");
  const [membershipEdit, setMembershipEdit] = useState("");
  // Handle Delete
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");
  // Detail
  const [detailShow, setDetailShow] = useState(false);
  const [detailCustomer, setDetailCustomer] = useState();
  const [historyCustomer, setHistoryCustomer] = useState([]);
  const [sumGrandTotal, setsumGrandTotal] = useState(0);
  const [activeId, setActiveId] = useState();
  const [detailDateStart, setDetailDateStart] = useState("");
  const [detailDateEnd, setDetailDateEnd] = useState("");
  const [tableDetailCount, setTableDetailCount] = useState(null);
  const [itemsPerPageDetail, setItemsPerPageDetail] = useState(null);
  const [currentDetailPage, setCurrentDetailPage] = useState(1);
  // ModalDetail
  const [modalDetailData, setModalDetailData] = useState({});
  // Modal Alert
  const [errorMsg, setErrorMsg] = useState("");

  // const fetchData = async (page = currentTablePage, search = searchValue) => {
  //   try {
  //     const {data} = await axios.get(
  //       `${API_URL}customers${
  //         search !== "" && search !== undefined
  //           ? searchType === "Customer Name"
  //             ? `?name=${search}&page=${page}`
  //             : searchType === "Membership"
  //             ? `?membership=${search}&page=${page}`
  //             : `?page=${page}`
  //           : `?page=${page}`
  //       }`,
  //       Session()
  //     );
  //     console.log(data.data);
  //     setTableData(data.data);
  //     setTableCount(data.meta.total);
  //     setItemsPerPage(data.meta.per_page);
  //   } catch (err) {
  //     if (!err?.response) {
  //       setErrorMsg("No Server Response");
  //     } else if (err.response?.status === 401) {
  //       setErrorMsg("Unauthorized, please login again!");
  //     } else {
  //       setErrorMsg("Can't get data");
  //     }
  //     setOpenAlert(true);
  //   }
  // };

  const fetchData = async (page = currentTablePage, search = searchValue) => {
    try {
      let url = `${API_URL}customers`;
      let params = {
        page: page,
      };
  
      if (search !== "" && search !== undefined) {
        if (searchType === "Customer Name") {
          params.name = search;
        } else if (searchType === "Membership") {
          params.membership = search;
        }
      }
  
      if (selectedMonth) {
        params.birthday = selectedMonth.value;
        // console.log(selectedMonth.value);
      }
  
      // console.log(url);
      // console.log(params);
      const { data } = await axios.get(url, {
        ...Session(),
        params: params,
      });
  
      // console.log(data.data);
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      // Error handling code...
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };
  

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData();
  }, [currentTablePage, searchValue, selectedMonth]); // Add `selectedMonth` here  

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page, searchValue);
  };

  // const showSearchedTablePage = (searchValue) => {
  //   setSearchValue(searchValue);
  //   setCurrentTablePage(1);
  //   fetchData(1, searchValue);
  // };

  const showSearchedTablePage = (searchValue) => {
    setSearchValue(searchValue);
    // setSelectedMonth(null); // Clear selected birth month when performing a new search
    setCurrentTablePage(1);
    fetchData(1, searchValue);
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(birthday);
    // console.log(phone);
    // console.log(name);
    // console.log(membership);
    try {
      await axios.post(
        `${API_URL}customers/create`,
        {
          birthday: birthday,
          phone: phone,
          name: name,
          membership: membership,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully added customer, if data didn't show you must refresh your browser"
      );
      setOpenAlert(true);
      setBirthday("");
      setPhone("");
      setName("");
      setMembership("");
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Add Customer");
      }
      setOpenAlert(true);
    }
  };

  const prepareEdit = (value) => {
    setIdEdit(value);
    getEditData(value);
    setOpenEditCustomer(true);
  };

  const getEditData = async (value) => {
    try {
      const {data} = await axios.get(
        `${API_URL}customers/${value}}`,
        Session()
      );
      setCodeEdit(data.data.code);
      setBirthdayEdit(data.data.birthday);
      setPhoneEdit(data.data.phone);
      setNameEdit(data.data.name);
      setMembershipEdit(data.data.membership);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    let phoneVal = phoneEdit.replace(/-/g, "");
    try {
      await axios.put(
        `${API_URL}customers/update/${idEdit}`,
        {
          code: codeEdit,
          name: nameEdit,
          phone: phoneVal,
          birthday: birthdayEdit,
          membership: membershipEdit,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully edited customer data, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Update Data");
      }
      setOpenAlert(true);
    }
  };

  const prepareDelete = (id) => {
    setIdDelete(id);
    getDeleteData(id);
    setOpenDeleteCustomer(true);
  };

  const getDeleteData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}customers/${id}}`,
        Session()
      );
      setNameDelete(data.data.name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("There is an error, please refresh or login again");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}customers/delete/${idDelete}`,
        Session()
      );
      fetchData();
      setErrorMsg("Succesfully deleted customer");
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Delete Data");
      }
      setOpenAlert(true);
    }
  };

  const fetchDetailData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}customers/${id}`,
        Session()
      );
      setDetailCustomer(data.data);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchDetailHistoryCust = async (
    id,
    dateStart = detailDateStart,
    dateEnd = detailDateEnd,
    page = currentDetailPage
  ) => {
    try {
      const {data} = await axios.get(
        `${API_URL}orders/customer/${id}${
          dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}&page=${page}`
              : `?page=${page}`
            : `?page=${page}`
        }`,
        Session()
      );
      // console.log(data.data);
      setHistoryCustomer(data.data);
      setTableDetailCount(data.meta.total);
      setsumGrandTotal(data.meta.sum_grand_total);
      setItemsPerPageDetail(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const detailData = (id) => {
    if (activeId === id) {
      setDetailShow(!detailShow);
      setHistoryCustomer([]);
    } else {
      fetchDetailData(id);
      setCurrentDetailPage(1);
      fetchDetailHistoryCust(id, detailDateStart, detailDateEnd, 1);
      setActiveId(id);
      setDetailShow(true);
    }
  };

  const showDetailTablePage = (page) => {
    setCurrentDetailPage(page);
    fetchDetailHistoryCust(activeId, detailDateStart, detailDateEnd, page);
  };

  const enterFilterDateStart = (val) => {
    setDetailDateStart(val);
    fetchDetailHistoryCust(activeId, val, detailDateEnd, currentDetailPage);
  };

  const enterFilterDateEnd = (val) => {
    setDetailDateEnd(val);
    fetchDetailHistoryCust(activeId, detailDateStart, val, currentDetailPage);
  };

  const getDetailDataModal = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}orders/${id}`,
        Session()
      );
      setModalDetailData(data.data);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const detailModal = (id) => {
    getDetailDataModal(id);
    setOpenDetailOrder(true);
  };

  const exportDetail = async () => {
    let {data} = await axios.get(
      `${API_URL}orders/customer/${activeId}${
        detailDateStart !== "" && detailDateStart !== undefined
          ? detailDateEnd !== "" && detailDateEnd !== undefined
            ? `?from=${detailDateStart}&to=${detailDateEnd}&paginate=false`
            : `?paginate=false`
          : `?paginate=false`
      }`,
      Session()
    );
    let customer = await axios.get(
      `${API_URL}customers/${activeId}`,
      Session()
    );
    let code = customer.data.data.code;
    let name = customer.data.data.name;
    let temp = data.data.map((data, index) => [
      index + 1,
      data.code,
      code,
      data.datetime,
      data.customer_name,
      data.discount_type,
      data.discount_amount,
      data.subtotal,
      data.discount_total,
      data.grand_total,
      data.payment_method,
      data.status,
    ]);
    const headings = [
      [
        "No",
        "Date & Time",
        "Transaction ID",
        "Customer ID",
        "Customer Name",
        "Discount Type",
        "Discount",
        "Sub Total",
        "Discount Total",
        "Grand Total",
        "Payment Method",
        "Status",
      ],
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(temp);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, temp, {origin: "A4", skipHeader: true});
    utils.book_append_sheet(wb, ws, `History Payment ${name}`);
    writeFileXLSX(wb, `History Payment ${name}.xlsx`);
  };

  const printDetail = async () => {
    let {data} = await axios.get(
      `${API_URL}orders/customer/${activeId}${
        detailDateStart !== "" && detailDateStart !== undefined
          ? detailDateEnd !== "" && detailDateEnd !== undefined
            ? `?from=${detailDateStart}&to=${detailDateEnd}&paginate=false`
            : `?paginate=false`
          : `?paginate=false`
      }`,
      Session()
    );
    let customer = await axios.get(
      `${API_URL}customers/${activeId}`,
      Session()
    );
    let code = customer.data.data.code;
    let name = customer.data.data.name;
    let temp = data.data.map((data, index) => [
      index + 1,
      data.code,
      data.datetime,
      data.customer_name,
      data.discount_type,
      data.discount_amount,
      data.subtotal,
      data.discount_total,
      data.grand_total,
      data.payment_method,
      data.status,
    ]);
    PrintReport(
      false,
      `${code} - ${name}`,
      "REKAP RIWAYAT TRANSAKSI CUSTOMER",
      ["ID Customer", "Nama Customer"],
      [code, name],
      [
        "No",
        "Date & Time",
        "Transaction ID",
        "Customer Name",
        "Discount Type",
        "Discount",
        "Sub Total",
        "Discount Total",
        "Grand Total",
        "Payment Method",
        "Status",
      ],
      temp
    );
  };

  return (
    <div className="flex flex-col h-full font-noto-sans">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <ModalCreateCustomer
        show={openAddCustomer}
        close={closeAddCustomerModal}
        birthdayValue={birthday}
        setBirthdayValue={setBirthday}
        nameValue={name}
        setNameValue={setName}
        membershipValue={membership}
        setMembershipValue={setMembership}
        phoneValue={phone}
        setPhoneValue={setPhone}
        submit={handleSubmit}
      />
      <ModalEditCustomer
        show={openEditCustomer}
        close={closeEditCustomerModal}
        birthdayEditValue={birthdayEdit}
        setBirthdayEditValue={setBirthdayEdit}
        nameEditValue={nameEdit}
        setNameEditValue={setNameEdit}
        membershipEditValue={membershipEdit}
        setMembershipEditValue={setMembershipEdit}
        phoneEditValue={phoneEdit}
        setPhoneEditValue={setPhoneEdit}
        submit={handleEdit}
      />
      <ModalDeleteCustomer
        show={openDeleteCustomer}
        close={closeDeleteCustomerModal}
        nameDeleteValue={nameDelete}
        submit={handleDelete}
      />
      <ModalShowDetailOrder
        show={openDetailOrder}
        close={closeDetailOrderModal}
        dataDetail={modalDetailData}
      />
      <div className="w-full flex flex-col mt-3 md:flex-row grow overflow-auto scrollbar-shown">
        <div
          className={`
          basis-full${detailShow ? " md:basis-1/2 lg:basis-4/6" : ""}`}
        >
          <div className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col p-5">
            <div className="flex flex-row flex-wrap mt-2 mb-4 relative">
              <div className="flex flex-row gap-2">
                <div className="text-black">
                  <DropdownSelect
                    header={
                      searchType !== "" && searchType !== undefined
                        ? searchType
                        : "Select Comparison..."
                    }
                    showExpandIcon={true}
                    headerClass="px-2 py-2 border rounded-lg flex flex-row items-center gap-0.5 whitespace-nowrap"
                  >
                    <DropdownOption
                      target={searchType}
                      setTarget={setSearchType}
                      value={"Customer Name"}
                    />
                    <DropdownOption
                      target={searchType}
                      setTarget={setSearchType}
                      value={"Membership"}
                    />
                  </DropdownSelect>
                </div>
                <Search
                  textColor={"text-black"}
                  bgColor={"bg-white"}
                  placeholder={`${
                    searchType === "Customer Name"
                      ? "Search by customer name"
                      : searchType === "Date of Birth"
                      ? "Example: 2022-08-31"
                      : "Example: vip"
                  }...`}
                  searchValue={searchValue}
                  setSearchValue={showSearchedTablePage}
                />

                <div className="relative ml-4 text-black">
                  <Select
                    options={months}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  />
                  {/* {selectedMonth && (
                    <div className="absolute right-0 bottom-[-10px] pr-4 pb-2 text-sm text-gray-500">
                      Selected Month: {selectedMonth.label}
                    </div>
                  )} */}
                </div>

                {/* <div className="flex items-center text-black">
                  <div className="mr-2">Month:</div>
                  <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="border border-gray-300 rounded px-2 py-1"
                  >
                    <option value="">Select a month</option>
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div> */}

              </div>

              <button
                type="submit"
                className="flex flex-row items-center sm:ml-auto h-10 px-3 py-2 bg-black rounded-lg whitespace-nowrap"
                onClick={openAddCustomerModal}
              >
                <MdAdd className="text-white mr-2" />
                <span>Add Customer</span>
              </button>
            </div>

            <p className="w-full text-black mb-4">Total List Customer : {tableCount} </p>

            {tableCount ? (
              <>
                <div className="h-full mb-3 overflow-y-auto scrollbar-shown">
                  <TableCustomers
                    tableData={tableData}
                    editRow={prepareEdit}
                    deleteRow={prepareDelete}
                    detailData={detailData}
                    currentPage={currentTablePage}
                  />
                </div>
                <Pagination
                  maxPage={Math.ceil(tableCount / itemsPerPage)}
                  currentPage={currentTablePage}
                  showTablePage={showTablePage}
                />
              </>
            ) : (
              <p className="w-full text-black">Waiting for Data</p>
            )}
          </div>
        </div>
        {detailCustomer && detailShow && (
          <div className="basis-full md:mt-0 md:ml-2 md:basis-1/2 lg:basis-2/6 mt-2">
            <CustomerDetail
              detailCustomer={detailCustomer}
              tabelDetailCustomer={historyCustomer}
              tableDetailCount={tableDetailCount}
              sumGrandTotal={sumGrandTotal}
              modalDetail={detailModal}
              dateStart={detailDateStart}
              setDateStart={enterFilterDateStart}
              dateEnd={detailDateEnd}
              setDateEnd={enterFilterDateEnd}
              maxPage={Math.ceil(tableDetailCount / itemsPerPageDetail)}
              currentPage={currentDetailPage}
              showTablePage={showDetailTablePage}
              exportDetail={exportDetail}
              printDetail={printDetail}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Customers;
