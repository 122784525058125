import {Dialog, Transition} from "@headlessui/react";
import {Fragment} from "react";
import {MdClose} from "react-icons/md";

const ModalShowDetailOrder = ({show, close, dataDetail}) => {
  const addDots = (nStr) => {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="div"
                  className="text-lg text-center font-medium leading-6 text-gray-900 p-8 pb-1"
                >
                  <div
                    onClick={close}
                    className="rounded-full p-0.5 top-2 right-2 bg-gray-200 absolute"
                    role="button"
                  >
                    <MdClose className="relative" />
                  </div>
                </Dialog.Title>
                <div className="flex flex-col p-5">
                  <h1>Order ID {dataDetail?.code}</h1>
                  <h1>Date: {dataDetail?.datetime}</h1>
                  {dataDetail.transactionItems ? (
                    <table className="mt-2 mb-2 font-nunito-sans text-xs w-full overflow-y-auto relative">
                      <thead>
                        <tr className="bg-[#F9F9FC] text-black text-left">
                          <th className="py-2 whitespace-nowrap">ID Product</th>
                          <th className="py-2 whitespace-nowrap">
                            Product Name
                          </th>
                          <th className="py-2 whitespace-nowrap">Price</th>
                          <th className="py-2 whitespace-nowrap">Discount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataDetail.transactionItems.map((data) => (
                          <tr
                            key={data.id}
                            className="mb-2 even:bg-[#F9F9FC] text-black text-left"
                          >
                            <td className="py-2 whitespace-nowrap">
                              {data.product_code}
                            </td>
                            <td className="py-2 whitespace-nowrap">
                              {data.product_name}
                            </td>
                            <td className="py-2 whitespace-nowrap">
                              Rp{data.price}
                            </td>
                            <td className="py-2 whitespace-nowrap">
                              {data.discount_type === "nominal"
                                ? "Rp" + data.discount_amount
                                : data.discount_type === "percent"
                                ? data.discount_amount + "%"
                                : "0"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <span>No transaction is recorded!</span>
                  )}
                  <ul className="flex flex-col">
                    <li className="flex justify-end mr-4">
                      Sub Total: Rp{addDots(dataDetail.subtotal)}
                    </li>
                    <li className="flex justify-end mr-4">
                      Discount: Rp{addDots(dataDetail.discount_total)}
                    </li>
                    <li className="flex justify-end mr-4 mb-2">
                      Total Payment: Rp{addDots(dataDetail.grand_total)}
                    </li>
                  </ul>
                  <div className="flex justify-end mt-2">
                    <button
                      className="bg-red-500 px-3 py-2 rounded-lg"
                      onClick={close}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalShowDetailOrder;
