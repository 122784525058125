import React from "react";

const TableReportCommission = ({tableData, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 15 + 1;
  }

  return (
    <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
      <thead className="sticky top-0">
        <tr className="bg-[#F9F9FC] text-black text-left">
          <th className="py-2 text-center whitespace-nowrap">No</th>
          <th className="py-2 text-center whitespace-nowrap">Date</th>
          <th className="py-2 text-center whitespace-nowrap">Transaction ID</th>
          <th className="py-2 text-center whitespace-nowrap">Employee Name</th>
          <th className="py-2 text-center whitespace-nowrap">Product Name</th>
          <th className="py-2 text-center whitespace-nowrap">Category Name</th>
          <th className="py-2 text-center whitespace-nowrap">Qty</th>
          <th className="py-2 text-center whitespace-nowrap">Price</th>
          <th className="py-2 text-right whitespace-nowrap">Commission Value</th>
          <th className="py-2 text-right whitespace-nowrap">Price After Discount</th>
          <th className="py-2 text-right whitespace-nowrap">Total Commission</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 &&
          tableData.map((row) => (
            <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
              <td className="py-4 text-center whitespace-nowrap">{startNumber++}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.datetime}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.code}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.employee_name}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.product_name}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.category_name}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.qty}</td>
              <td className="py-2 text-right whitespace-nowrap">{row.price}</td>
              <td className="py-2 text-right whitespace-nowrap">
                {row.commission}
              </td>
              <td className="py-2 whitespace-nowrap text-right">
                {row.price_after_discount}
              </td>
              <td className="py-2 whitespace-nowrap text-right">{row.fee}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableReportCommission;
