import axios from "axios";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import BackButton from "../components/BackButton";
import DropdownMenuExport from "../components/DropdownMenuExport";
import Pagination from "../components/Pagination";
import TabTitle from "../utils/GeneralFunction";
import Session from "../Session";
import FilterByDate from "../components/FilterByDate";
import TableEmployeeSingle from "../components/TableEmployeeSingle";
import {utils, writeFileXLSX} from "xlsx";
import ModalAlert from "../components/ModalAlert";
import PrintReport from "../utils/PrintReport";
import { API_URL } from '../utils/api';

const EmployeeSingle = () => {
  const {state} = useLocation();
  const {employeeId} = state;

  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setMessage("");
  };

  const [message, setMessage] = useState("");

  const [profileEmployee, setProfileEmployee] = useState("");
  const [salaryEmployee, setSalaryEmployee] = useState("");
  const [revenue, setRevenue] = useState();

  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);

  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const capitalizeEachWord = (sentence) => {
    const words = sentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  const fetchProfileEmployee = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees/${employeeId}`,
        Session()
      );
      setProfileEmployee(data.data);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchSalaryEmployee = async (
    dateStart,
    dateEnd,
    page = currentTablePage
  ) => {
    try {
      const {data} = await axios.get(
        `${API_URL}orders/employee/${employeeId}${
          dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}&page=${page}`
              : `?page=${page}`
            : `?page=${page}`
        }`,
        Session()
      );
      setRevenue(data);
      setSalaryEmployee(data);
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setMessage("No Server Response");
      } else if (err.response?.status === 401) {
        setMessage("Unauthorized, please login again!");
      } else {
        setMessage("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchProfileEmployee();
    fetchSalaryEmployee();
  }, []);

  TabTitle(`${profileEmployee?.name} - Katto Haircut`);

  const prepareEnterDateStart = (val) => {
    setDateStart(val);
    setCurrentTablePage(1);
    fetchSalaryEmployee(val, dateEnd, 1);
  };

  const prepareEnterDateEnd = (val) => {
    setDateEnd(val);
    setCurrentTablePage(1);
    fetchSalaryEmployee(dateStart, val, 1);
  };

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchSalaryEmployee(dateStart, dateEnd, page);
  };

  const exportAll = async () => {
    let {data} = await axios.get(
      `${API_URL}orders/employee/${employeeId}${
        dateStart !== "" && dateStart !== undefined
          ? dateEnd !== "" && dateEnd !== undefined
            ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
            : `?paginate=false`
          : `?paginate=false`
      }`,
      Session()
    );
    let temp = data.data.map((data, index) => [
      index + 1,
      data.date,
      data.code,
      data.product_name,
      data.price,
      data.activities,
      data.fee,
    ]);
    const headings = [
      [
        "No",
        "Date",
        "Transaction ID",
        "Product Name",
        "Price",
        "Activities",
        "Total Fee",
      ],
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(temp);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, temp, {origin: "A4", skipHeader: true});
    utils.book_append_sheet(wb, ws, "Employee Data");
    writeFileXLSX(wb, "Employee Detail Data.xlsx");
  };

  const printAll = async () => {
    let {data} = await axios.get(
      `${API_URL}orders/employee/${employeeId}${
        dateStart !== "" && dateStart !== undefined
          ? dateEnd !== "" && dateEnd !== undefined
            ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
            : `?paginate=false`
          : `?paginate=false`
      }`,
      Session()
    );
    let temp = data.data.map((data, index) => [
      index + 1,
      data.date,
      data.code,
      data.product_name,
      data.price,
      data.activities,
      data.fee,
    ]);
    PrintReport(
      false,
      `${profileEmployee?.code} - ${profileEmployee?.name}`,
      "LAPORAN EMPLOYEE",
      ["ID Employee", "Employee Name", "Employee Job", "Salary"],
      [
        profileEmployee?.code,
        profileEmployee?.name,
        profileEmployee?.job_name,
        `Rp ${data.salary}`,
      ],
      [
        "No",
        "Date",
        "Transaction ID",
        "Product Name",
        "Price",
        "Activities",
        "Total Fee",
      ],
      temp
    );
  };

  const closeAll = () => {};

  return (
    <div className="flex flex-col h-full font-noto-sans">
      <div className="flex flex-row">
        <BackButton />
      </div>
      <div className="w-full flex flex-col mt-3 md:flex-row grow overflow-auto scrollbar-shown">
        <ModalAlert
          show={openAlert}
          close={closeAlertModal}
          message={message}
        />
        <div className="basis-full">
          <div className="bg-white relative rounded-lg overflow-hidden flex h-full flex-col p-3">
            {salaryEmployee && profileEmployee && (
              <div className="flex flex-row justify-between sm:justify-start font-nunito-sans my-2 w-full">
                <div className="flex flex-col md:flex-row md:gap-10">
                  <div className="flex flex-col">
                    <h1 className="text-black">{profileEmployee?.name}</h1>
                    <h1 className="text-gray-400 font-semibold text-md">
                      {profileEmployee?.code}
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="text-black">
                      Job Desk
                    </h1>
                    <h1 className="text-green-500 font-semibold text-2xl">
                      {capitalizeEachWord(profileEmployee?.job_name)}
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="text-black">
                    Total Transaction Fee
                    </h1>
                    <h1 className="text-green-500 font-semibold text-2xl">
                      Rp{salaryEmployee?.salary}
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="text-black">
                    Total Transaction
                    </h1>
                    <h1 className="text-green-500 font-semibold text-2xl">
                      Rp{salaryEmployee?.total_revenue}
                    </h1>
                  </div>
                </div>
                <DropdownMenuExport
                  export={exportAll}
                  print={printAll}
                  close={closeAll}
                />
              </div>
            )}
            <div
              id="noprint"
              className="flex flex-col md:flex-row md:gap-2 border-t mb-2 pt-2"
            >
              <FilterByDate
                dateStart={dateStart}
                setDateStart={prepareEnterDateStart}
                dateEnd={dateEnd}
                setDateEnd={prepareEnterDateEnd}
              />
            </div>

            <p className="w-full text-black mb-4">Total Transaction Employee : {tableCount} </p>

            <div
              id="printArea"
              className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col mb-8"
            >
              <TableEmployeeSingle
                tableData={tableData}
                currentPage={currentTablePage}
              />
            </div>
            <Pagination
              maxPage={Math.ceil(tableCount / itemsPerPage)}
              currentPage={currentTablePage}
              showTablePage={showTablePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeSingle;
