import React from "react";

const DropdownOption = ({
  target,
  setTarget,
  value,
  extraClassList = "",
  ...props
}) => {
  return (
    <button
      className={`${
        target === value ? "text-black font-bold " : "text-[#84849D] "
      }${extraClassList}flex flex-row items-center px-4 py-3 bg-transparent hover:bg-primary-100 hover:bg-opacity-5 not-italic font-semibold text-sm leading-5`}
      onClick={() => setTarget(value)}
    >
      <span className="text-left">{value}</span>
    </button>
  );
};

export default DropdownOption;
