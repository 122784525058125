// source https://codesandbox.io/s/beautiful-tristan-l045pb?file=/index.html

const PrintReport = (
  isPopUp = false,
  title,
  recapTitle,
  recapList,
  recapData,
  tableHeaderData,
  tableBodyData
) => {
  let a = window.open("", "", `${isPopUp ? "popup=true" : "popup=false"}`);
  a.document.write(
    `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8" /> <meta name="viewport" content="width=device-width, initial-scale=1.0" /> <meta http-equiv="X-UA-Compatible" content="ie=edge" /> <title>${title}</title>`
  );
  a.document.write(
    `<style> * { margin: 0; padding: 0; font-family: sans-serif; box-sizing: border-box; } .flex-row { display: flex; flex-direction: row; } .flex-col { display: flex; flex-direction: column; } .items-center { align-items: center; } .content-center { justify-content: center; } .content-between { justify-content: space-between; } .text-left { text-align: left; } .text-right { text-align: right; } .text-center { text-align: center; } .gap-small { gap: 8px; } .gap-medium { gap: 16px; } .gap-large { gap: 24px; } .padding-small { padding: 8px; } .padding-medium { padding: 16px; } .padding-x-medium { padding-left: 8px; padding-right: 8px; } .border-dot { border-top: 1px dashed #000; } .table-header { background-color: lightgray; } .table-border-collapse { border-collapse: collapse; } .color-adjust-exact { -webkit-print-color-adjust: exact; color-adjust: exact; } @page { margin: 5mm 2.5mm 0 0; } @media print { .hidden-print, .hidden-print * { display: none !important; } } </style>`
  );
  a.document.write(`</head><body class="color-adjust-exact">`);
  // header
  a.document.write(
    `<header class="padding-small flex-col gap-small"> <h2 class="text-center">Katto Hair Design</h2> 
      <p class="text-center"> Jl. Langensari, Klitren, Kec. Gondokusuman, Kota Yogyakarta, DIY 55221 </p>
      <p class="text-center"> 0857-2397-4265 </p>
    </header>`
  );
  a.document.write(`<main>`);
  // if there is recap data
  if (recapData !== "") {
    a.document.write(
      `<div class="flex-col padding-small gap-medium"> <div><h4>${recapTitle}</h4></div>`
    );
    a.document.write(`<div class="flex-row gap-large">`);
    a.document.write(`<div class="flex-col">`);
    // list recap, this is text align left
    recapList.forEach((data) => {
      a.document.write(`<p>${data}</p>`);
    });
    a.document.write(`</div>`);
    a.document.write(`<div class="flex-col">`);
    // data of list recap, this is text aligh right
    recapData.forEach((data) => {
      a.document.write(`<p class="text-right">${data}</p>`);
    });
    a.document.write(`</div>`);
    a.document.write(`</div></div>`);
  }
  // table
  a.document.write(
    `<div class="padding-small"> <table class="table-border-collapse">`
  );
  // table header
  a.document.write(`<thead> <tr class="table-header">`);
  tableHeaderData.forEach((header) => {
    a.document.write(`<th class="padding-small">${header}</th>`);
  });
  a.document.write(`</tr></thead>`);
  // table body
  a.document.write(`<tbody>`);
  tableBodyData.forEach((body) => {
    a.document.write(`<tr>`);
    body.forEach((data) => {
      a.document.write(`<td class="padding-small">${data}</td>`);
    });
    a.document.write(`</tr>`);
  });
  a.document.write(`</tbody>`);
  a.document.write(`</table></div>`);
  a.document.write(`</main>`);
  a.document.write(
    `<div class="flex-row content-center hidden-print"> <button onclick="window.print()">Print</button> </div>`
  );
  a.document.write(`</body></html>`);
  a.document.close();
  a.print();
};

export default PrintReport;
