import {MdAdd} from "react-icons/md";
import React, {useEffect, useState} from "react";
import TabTitle from "../utils/GeneralFunction";
import TableEmployee from "../components/TableEmployee";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import ModalCreateEmployee from "../components/ModalCreateEmployee";
import ModalEditEmployee from "../components/ModalEditEmployee";
import ModalDeleteEmployee from "../components/ModalDeleteEmployee";
import ModalAlert from "../components/ModalAlert";
import DropdownSelect from "../components/DropdownSelect";
import DropdownOption from "../components/DropdownOption";
import Session from "../Session";
import { API_URL } from '../utils/api';

const Employee = (props) => {
  TabTitle("Employee - Kato Haircut");
  let navigate = useNavigate();
  // Modal
  const [openAddEmployee, setOpenAddEmployee] = useState(false);
  const closeAddEmployeeModal = () => setOpenAddEmployee(false);
  const openAddEmployeeModal = () => setOpenAddEmployee(true);
  const [openEditEmployee, setOpenEditEmployee] = useState(false);
  const closeEditEmployeeModal = () => setOpenEditEmployee(false);
  const [openDeleteEmployee, setOpenDeleteEmployee] = useState(false);
  const closeDeleteEmployeeModal = () => setOpenDeleteEmployee(false);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Search
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("Name");
  // Handle Create
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dataJob, setDataJob] = useState("");
  const [job, setJob] = useState("");
  // Handle Edit
  const [idEdit, setIdEdit] = useState("");
  const [codeEdit, setCodeEdit] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [nameEdit, setNameEdit] = useState("");
  const [jobEdit, setJobEdit] = useState("");
  // Hanlde Edit
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");
  // Print function
  const idTable = "tableEmployee";

  const [errorMsg, setErrorMsg] = useState("");

  const fetchData = async (page = currentTablePage, search = "") => {
    try {
      // const {data} = await axios.get(
      //   `${API_URL}employees${
      //     search !== "" && search !== undefined
      //       ? `?name=${search}&page=${page}`
      //       : `?page=${page}`
      //   }`,
      //   Session()
      // );
      const searchParam = searchType.toLowerCase() === "name" ? "name" : "job";
      const { data } = await axios.get(
        `${API_URL}employees?${searchParam}=${search}&page=${page}`,
        Session()
      );
      console.log(data.data)
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchData();
    fetchJobData();
  }, []);

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page);
  };

  const showSearchedTablePage = (searchValue) => {
    setSearchValue(searchValue);
    setCurrentTablePage(1);
    fetchData(currentTablePage, searchValue);
  };

  const fetchJobData = async () => {
    try {
      const getData = await axios.get(
        `${API_URL}employees/jobs`,
        Session()
      );
      setDataJob(getData.data.data);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${API_URL}employees/create`,
        {
          name: name,
          phone: phone,
          job_id: job,
        },
        Session()
      );
      fetchData();
      setName("");
      setPhone("");
      setJob("");
      setErrorMsg(
        "Succesfully added employee, if data didn't show you must refresh your browser"
      );
      setOpenAlert(true);
    } catch ({err}) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Add Employee");
      }
      setOpenAlert(true);
    }
  };

  const prepareEdit = (value) => {
    setIdEdit(value.id);
    setNameEdit(value.name);
    setJobEdit(value.job_name);
    setPhoneEdit(value.phone);
    getEditData(value);
    setOpenEditEmployee(true);
  };

  const getEditData = async (value) => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees/${value.id}}`,
        Session()
      );
      setCodeEdit(data.data.code);
      setPhoneEdit(data.data.phone);
      setNameEdit(data.data.name);
      setJobEdit(data.data.job_name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      let i;
      let job_id = 0;
      for (i in dataJob) {
        if (dataJob[i].name == jobEdit) {
          job_id = dataJob[i].id;
        }
      }
      await axios.put(
        `${API_URL}employees/update/${idEdit}`,
        {
          code: codeEdit,
          phone: phoneEdit,
          name: nameEdit,
          job_id: job_id,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully edited employee data, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Update Data");
      }
      setOpenAlert(true);
    }
  };

  const prepareDelete = (id) => {
    setIdDelete(id);
    getDeleteData(id);
    setOpenDeleteEmployee(true);
  };

  const getDeleteData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees/${id}}`,
        Session()
      );
      setNameDelete(data.data.name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("There is an error, please refresh or login again");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}employees/delete/${idDelete}`,
        Session()
      );
      fetchData();
      setErrorMsg("Succesfully deleted employee data");
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Delete Data");
      }
      setOpenAlert(true);
    }
  };

  const handleSeeDetail = (id) => {
    navigate("detail", {state: {employeeId: id}});
  };

  return (
    <div className="w-full h-full flex flex-col grow overflow-auto scrollbar-shown">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <ModalCreateEmployee
        show={openAddEmployee}
        close={closeAddEmployeeModal}
        name={name}
        setNameValue={setName}
        dataJob={dataJob}
        jobValue={job}
        setJobValue={setJob}
        phoneValue={phone}
        setPhoneValue={setPhone}
        submit={handleSubmit}
      />
      <ModalEditEmployee
        show={openEditEmployee}
        close={closeEditEmployeeModal}
        codeEditValue={codeEdit}
        setCodeEditValue={setCodeEdit}
        nameEditValue={nameEdit}
        setNameEditValue={setNameEdit}
        phoneEditValue={phoneEdit}
        setPhoneEditValue={setPhoneEdit}
        dataJob={dataJob}
        jobEditValue={jobEdit}
        setJobEditValue={setJobEdit}
        submit={handleEdit}
      />
      <ModalDeleteEmployee
        show={openDeleteEmployee}
        close={closeDeleteEmployeeModal}
        nameDeleteValue={nameDelete}
        submit={handleDelete}
      />
      <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col">
        <div className="flex justify-between">
          <div className="flex flex-row gap-2">
            <div className="text-black">
              <DropdownSelect
                header={
                  searchType !== "" && searchType !== undefined
                    ? searchType
                    : "Select Comparison..."
                }
                showExpandIcon={true}
                headerClass="px-2 py-2 border rounded-lg flex flex-row items-center gap-0.5 whitespace-nowrap"
              >
                <DropdownOption
                  target={searchType}
                  setTarget={setSearchType}
                  value={"Name"}
                />
                <DropdownOption
                  target={searchType}
                  setTarget={setSearchType}
                  value={"Job"}
                />
              </DropdownSelect>
            </div>
            <Search
              textColor={"text-black"}
              bgColor={"bg-white"}
              placeholder={`Search by ${
                searchType === "Name"
                  ? "name"
                  : "job"
              }...`}
              searchValue={searchValue}
              setSearchValue={showSearchedTablePage}
            />
          </div>
          
          <button
            type="submit"
            className="flex flex-row ml-2 items-center sm:ml-auto h-10 px-3 py-2 bg-black rounded-lg"
            onClick={openAddEmployeeModal}
          >
            <MdAdd className="text-white mr-2" />
            <span>Add Employee</span>
          </button>
        </div>

        <p className="w-full text-black mb-4">Total List Employee : {tableCount} </p>

        {tableCount ? (
          <>
            <div className="h-full mb-3 overflow-y-auto scrollbar-shown">
              <TableEmployee
                tableData={tableData}
                idTable={idTable}
                editRow={prepareEdit}
                deleteRow={prepareDelete}
                detail={handleSeeDetail}
                currentPage={currentTablePage}
              />
            </div>
            <div className="flex sticky bottom-0 bg-white">
              <Pagination
                maxPage={Math.ceil(tableCount / itemsPerPage)}
                currentPage={currentTablePage}
                showTablePage={showTablePage}
              />
            </div>
          </>
        ) : (
          <p className="w-full text-black">Waiting for Data</p>
        )}
      </div>
    </div>
  );
};

export default Employee;
