import FilterByDate from "./FilterByDate";
import Pagination from "./Pagination";
import DropdownMenuExport from "./DropdownMenuExport";

const PresensiDetail = ({
  detailPresensi,
  totalPresensi,
  employeeName,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  maxPage,
  currentPage,
  showTablePage,
  exportAll,
  printAll,
  close,
}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }

  const capitalizeEachWord = (sentence) => {
    const words = sentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  return (
    <div className="flex flex-col h-full font-noto-sans">
      <div className="bg-white rounded-lg overflow-hidden flex h-full flex-col gap-4 p-5">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <h1 className="text-black font-bold text-lg mt-1 mb-2 mr-20">
              {employeeName}
            </h1>
            <h5 className="text-black text-lg">
              Total Presensi: {totalPresensi}
            </h5>
            <div className="mt-2">
              <FilterByDate
                dateStart={dateStart}
                setDateStart={setDateStart}
                dateEnd={dateEnd}
                setDateEnd={setDateEnd}
              />
            </div>
          </div>
          <DropdownMenuExport
            export={exportAll}
            print={printAll}
            close={close}
          />
        </div>
        <div
          id="printArea"
          className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown h-full flex flex-col mb-8"
        >
          <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
            <thead className="sticky top-0">
              <tr className="bg-[#F9F9FC] text-black text-left">
                <th className="py-2 whitespace-nowrap">No</th>
                <th className="py-2 whitespace-nowrap">Name</th>
                <th className="py-2 whitespace-nowrap">Shift</th>
                <th className="py-2 whitespace-nowrap">Status</th>
                <th className="py-2 whitespace-nowrap">Coming</th>
                <th className="py-2 whitespace-nowrap">Return</th>
              </tr>
            </thead>
            {detailPresensi.length > 0 && (
              <tbody>
                {detailPresensi.map((row) => (
                  <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
                    <td className="py-2 whitespace-nowrap">{startNumber++}</td>
                    <td className="py-2 whitespace-nowrap">
                      {row.employee_name}
                    </td>
                    <td className="py-2 whitespace-nowrap">
                      {capitalizeEachWord(row.shift)}
                    </td>
                    <td className="py-2 whitespace-nowrap">
                      {capitalizeEachWord(row.status)}
                    </td>
                    <td className="py-2">{row.coming_time}</td>
                    <td className="py-2">{row.return_time}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {detailPresensi.length > 0 && (
          <Pagination
            maxPage={maxPage}
            currentPage={currentPage}
            showTablePage={showTablePage}
          />
        )}
      </div>
    </div>
  );
};

export default PresensiDetail;
