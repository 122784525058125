import {MdAdd} from "react-icons/md";
import React, {useEffect, useState} from "react";
import Search from "../components/Search";
import Pagination from "../components/Pagination";
import TabTitle from "../utils/GeneralFunction";
import TableJobs from "../components/TableJobs";
import axios from "axios";
import ModalCreateJob from "../components/ModalCreateJobs";
import ModalEditJob from "../components/ModalEditJobs";
import ModalDeleteJob from "../components/ModalDeleteJobs";
import Session from "../Session";
import ModalAlert from "../components/ModalAlert";
import { API_URL } from '../utils/api';


const Jobs = () => {
  TabTitle("Jobs Category - Kato Haircut");
  // Modal
  const [openAddJob, setOpenAddJob] = useState(false);
  const closeAddJobModal = () => setOpenAddJob(false);
  const openAddJobModal = () => setOpenAddJob(true);
  const [openEditJob, setOpenEditJob] = useState(false);
  const closeEditJobModal = () => setOpenEditJob(false);
  const [openDeleteJob, setOpenDeleteJob] = useState(false);
  const closeDeleteJobModal = () => setOpenDeleteJob(false);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Search
  const [searchValue, setSearchValue] = useState();
  // Handle Create
  const [name, setName] = useState("");
  // Handle Edit
  const [idEdit, setIdEdit] = useState("");
  const [codeEdit, setCodeEdit] = useState("");
  const [nameEdit, setNameEdit] = useState("");
  // Hanlde Delete
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const fetchData = async (page = currentTablePage, search = "") => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees/jobs${
          search !== "" ? `?name=${search}&?page=${page}` : `?page=${page}`
        }`,
        Session()
      );
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page);
  };

  const showSearchedTablePage = (searchValue) => {
    setSearchValue(searchValue);
    setCurrentTablePage(1);
    fetchData(currentTablePage, searchValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log({name});
    try {
      await axios.post(
        `${API_URL}employees/jobs/create`,
        {
          name: name,
        },
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully added job, if data didn't show you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Add Customer");
      }
      setOpenAlert(true);
    }
  };

  const prepareEdit = (value) => {
    setIdEdit(value);
    getEditData(value);
    setOpenEditJob(true);
  };

  const getEditData = async (value) => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees/jobs/${value}}`,
        Session()
      );
      setCodeEdit(data.data.code);
      setNameEdit(data.data.name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    // console.log({code: codeEdit, name: nameEdit});
    try {
      await axios.put(
        `${API_URL}employees/jobs/update/${idEdit}`,
        {code: codeEdit, name: nameEdit},
        Session()
      );
      fetchData();
      setErrorMsg(
        "Succesfully edited job data, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Update Data");
      }
      setOpenAlert(true);
    }
  };

  const prepareDelete = (id) => {
    setIdDelete(id);
    getDeleteData(id);
    setOpenDeleteJob(true);
  };

  const getDeleteData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees/jobs/${id}}`,
        Session()
      );
      setNameDelete(data.data.name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("There is an error, please refresh or login again");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}employees/jobs/delete/${idDelete}`,
        Session()
      );
      fetchData();
      setErrorMsg("Succesfully deleted job");
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed Delete Data");
      }
      setOpenAlert(true);
    }
  };

  return (
    <div className="w-full h-full flex flex-col grow overflow-auto scrollbar-shown">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <ModalCreateJob
        show={openAddJob}
        close={closeAddJobModal}
        name={name}
        setNameValue={setName}
        submit={handleSubmit}
      />
      <ModalEditJob
        show={openEditJob}
        close={closeEditJobModal}
        nameEditValue={nameEdit}
        setNameEditValue={setNameEdit}
        submit={handleEdit}
      />
      <ModalDeleteJob
        show={openDeleteJob}
        close={closeDeleteJobModal}
        nameDeleteValue={nameDelete}
        submit={handleDelete}
      />
      <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col">
        <div className="flex justify-between">
          <Search
            textColor={"text-black"}
            bgColor={"bg-white"}
            placeholder={"Search by name..."}
            searchValue={searchValue}
            setSearchValue={showSearchedTablePage}
          />
          <button
            type="submit"
            className="flex flex-row ml-2 items-center sm:ml-auto h-10 px-3 py-2 bg-black rounded-lg"
            onClick={openAddJobModal}
          >
            <MdAdd className="text-white mr-2" />
            <span>Add Job</span>
          </button>
        </div>
        {tableCount ? (
          <>
            <div className="h-full mb-3 overflow-y-auto scrollbar-shown">
              <TableJobs
                tableData={tableData}
                editRow={prepareEdit}
                deleteRow={prepareDelete}
                currentPage={currentTablePage}
              />
            </div>
            <Pagination
              maxPage={Math.ceil(tableCount / itemsPerPage)}
              currentPage={currentTablePage}
              showTablePage={showTablePage}
            />
          </>
        ) : (
          <p className="w-full text-black">Waiting for Data</p>
        )}
      </div>
    </div>
  );
};

export default Jobs;
