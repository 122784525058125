import ReportNavLink from "../components/ReportNavLink";
import TabTitle from "../utils/GeneralFunction";
import PrintInvoice from "../utils/PrintInvoice";
import PrintReport from "../utils/PrintReport";
import TableOrder from "../components/TableOrder";
import Search from "../components/Search";
import DropdownMenuExport from "../components/DropdownMenuExport";
import {useState} from "react";
import {useEffect} from "react";
import axios from "axios";
import Pagination from "../components/Pagination";
import ReportOrderDetail from "../components/ReportOrderDetail";
import Session from "../Session";
import FilterByDate from "../components/FilterByDate";
import {utils, writeFileXLSX} from "xlsx";
import ModalAlert from "../components/ModalAlert";
import ModalDeleteOrderData from "../components/ModalDeleteOrderData";
import DropdownSelect from "../components/DropdownSelect";
import DropdownOption from "../components/DropdownOption";
import { writeFile } from "xlsx";
import { saveAs } from "file-saver";
import { API_URL } from '../utils/api';

const ReportOrder = () => {
  TabTitle("Order - Kato Haircut");
  // Modal
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
  const closeDeleteOrderModal = () => {
    setOpenDeleteOrder(false);
  };
  const [orderId, setOrderId] = useState(null);
  const [orderCode, setOrderCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Search
  const [searchType, setSearchType] = useState("No Transaction");
  const [searchValue, setSearchValue] = useState("");
  // Detail
  const [detailShow, setDetailShow] = useState(false);
  const [detailOrder, setDetailOrder] = useState();
  const [activeId, setActiveId] = useState();
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  // const fetchData = async (
  //   page = currentTablePage,
  //   search = "",
  //   dateStart,
  //   dateEnd
  // ) => {
  //   try {
  //     const {data} = await axios.get(
  //       `${API_URL}orders${
  //         search !== "" && search !== undefined
  //           ? searchType === "No Transaction"
  //             ? page !== "" && page !== undefined
  //               ? `?searchCode=${search}&page=${page}`
  //               : `?searchCode=${search}`
  //             : searchType === "Customer Name"
  //             ? page !== "" && page !== undefined
  //               ? `?searchCustomerName=${search}&page=${page}`
  //               : `?searchCustomerName=${search}`
  //             : searchType === "Payment Method"
  //             ? page !== "" && page !== undefined
  //               ? `?searchPaymentMethod=${search}&page=${page}`
  //               : `?searchPaymentMethod=${search}`
  //             : page !== "" && page !== undefined
  //             ? `?page=${page}`
  //             : `?page=${page}`
  //           : dateStart !== "" && dateStart !== undefined
  //           ? dateEnd !== "" && dateEnd !== undefined
  //             ? page !== "" && page !== undefined
  //               ? `?from=${dateStart}&to=${dateEnd}&page=${page}`
  //               : `?from=${dateStart}&to=${dateEnd}`
  //             : page !== "" && page !== undefined
  //             ? `?from=${dateStart}&page=${page}`
  //             : `?from=${dateStart}`
  //           : dateEnd !== "" && dateEnd !== undefined
  //           ? page !== "" && page !== undefined
  //             ? `?to=${dateEnd}&page=${page}`
  //             : `?to=${dateEnd}`
  //           : page !== "" && page !== undefined
  //           ? `?page=${page}`
  //           : `?page=${page}`
  //       }`,
  //       Session()
  //     );

  //     console.log(data.data); // Log the server response
  //     setTableData(data.data);
  //     setTableCount(data.meta.total);
  //     setItemsPerPage(data.meta.per_page);
  //   } catch (err) {
  //     console.error("Error fetching data:", err);
  //     if (!err?.response) {
  //       setErrorMsg("No Server Response");
  //     } else if (err.response?.status === 401) {
  //       setErrorMsg("Unauthorized, please login again!");
  //     } else {
  //       setErrorMsg("Can't get data");
  //     }
  //     setOpenAlert(true);
  //   }
  // };

  const fetchData = async (
    page = currentTablePage,
    search = "",
    dateStart = "",
    dateEnd = ""
  ) => {
    try {
      let url = `${API_URL}orders?`;
  
      if (search !== "") {
        if (searchType === "No Transaction") {
          url += `searchCode=${search}`;
        } else if (searchType === "Customer Name") {
          url += `searchCustomerName=${search}`;
        } else if (searchType === "Payment Method") {
          url += `searchPaymentMethod=${search}`;
        }
      }
  
      if (dateStart !== "" && dateEnd !== "") {
        if (search !== "") {
          url += "&";
        }
        url += `from=${dateStart}&to=${dateEnd}`;
      }
  
      if (page !== "" && page !== undefined) {
        if (search !== "" || dateStart !== "" || dateEnd !== "") {
          url += "&";
        }
        url += `page=${page}`;
      }
      // console.log(url); 
      const { data } = await axios.get(url, Session());
      // console.log(data.data); // Log the server response
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      console.error("Error fetching data:", err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };  

  const fetchDetailData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}orders/${id}`,
        Session()
      );
      setDetailOrder(data.data);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  // const exportAll = async () => {
  //   // ambil data dari API
  //   let {data} = await axios.get(
  //     `${API_URL}orders${
  //       searchValue !== "" && searchValue !== undefined
  //         ? searchType === "No Transaction"
  //           ? `?searchCode=${searchValue}&paginate=false`
  //           : searchType === "Customer Name"
  //           ? `?searchCustomerName=${searchValue}&paginate=false`
  //           : searchType === "Payment Method"
  //           ? `?searchPaymentMethod=${searchValue}&paginate=false`
  //           : `?paginate=false`
  //         : dateStart !== "" && dateStart !== undefined
  //         ? dateEnd !== "" && dateEnd !== undefined
  //           ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
  //           : `?paginate=false`
  //         : `?paginate=false`
  //     }`,
  //     Session()
  //   );
  //   // seleksi data yang disediakan dari BE
  //   let temp = data.data.map((data, index) => [
  //     index + 1,
  //     data.datetime,
  //     data.code,
  //     data.status,
  //     data.customer_name,
  //     data.payment_method,
  //     data.subtotal,
  //     // data.discount_type,
  //     // data.discount_amount,
  //     data.discount_total,
  //     data.grand_total,
  //   ]);
  //   const headings = [
  //     [
  //       "No.",
  //       "Date & Time",
  //       "No Transaction",
  //       "Status",
  //       "Name Member",
  //       "Payment Method",
  //       "Price",
  //       // "Discount Type",
  //       // "Discount Amount",
  //       "Discount Total",
  //       "Total",
  //     ],
  //   ];
  //   const wb = utils.book_new();
  //   const ws = utils.json_to_sheet(temp);
  //   utils.sheet_add_aoa(ws, headings);
  //   utils.sheet_add_json(ws, temp, {origin: "A4", skipHeader: true});
  //   utils.book_append_sheet(wb, ws, "Report Data");
  //   writeFileXLSX(wb, "Report Order Data.xlsx");
  // };

  const exportAll = async () => {
    try {
      // Get data from the API
      let { data } = await axios.get(
        `${API_URL}orders${
          searchValue !== "" && searchValue !== undefined
            ? searchType === "No Transaction"
              ? `?searchCode=${searchValue}&paginate=false`
              : searchType === "Customer Name"
              ? `?searchCustomerName=${searchValue}&paginate=false`
              : searchType === "Payment Method"
              ? `?searchPaymentMethod=${searchValue}&paginate=false`
              : `?paginate=false`
            : dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
              : `?paginate=false`
            : `?paginate=false`
        }`,
        Session()
      );
  
      // Select the data provided by the backend
      let temp = data.data.map((data, index) => [
        index + 1,
        data.datetime,
        data.code,
        data.status,
        data.customer_name,
        data.payment_method,
        data.subtotal,
        data.discount_total,
        data.grand_total,
      ]);
  
      const headings = [
        [
          "No.",
          "Date & Time",
          "No Transaction",
          "Status",
          "Name Member",
          "Payment Method",
          "Price",
          "Discount Total",
          "Total",
        ],
      ];
  
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(temp);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, temp, {origin: "A4", skipHeader: true});
      utils.book_append_sheet(wb, ws, "Report Data");
      writeFileXLSX(wb, "Report Order Data.xlsx");    
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const closeAll = () => {};

  const addDots = (nStr) => {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  };

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page, searchValue, dateStart, dateEnd);
  };

  const showSearchedTablePage = (searchValue) => {
    setSearchValue(searchValue);
    setCurrentTablePage(1);
    // setDateStart("");
    // setDateEnd("");
    fetchData(1, searchValue, dateStart, dateEnd);
  };

  const detailData = (id) => {
    if (activeId === id) {
      setDetailShow(!detailShow);
    } else {
      fetchDetailData(id);
      setActiveId(id);
      setDetailShow(true);
    }
  };

  const printData = async (type, data) => {
    if (type === "invoice") {
      PrintInvoice(
        false,
        `${data.code} - ${data.customer_name}`,
        data.code,
        data.status.toUpperCase(),
        data.transactionItems,
        addDots(data.subtotal),
        addDots(data.discount_total),
        addDots(data.grand_total),
        data.datetime
      );
    } else if (type === "report") {
      let {data} = await axios.get(
        `${API_URL}orders${
          searchValue !== "" && searchValue !== undefined
            ? searchType === "No Transaction"
              ? `?searchCode=${searchValue}&paginate=false`
              : searchType === "Customer Name"
              ? `?searchCustomerName=${searchValue}&paginate=false`
              : searchType === "Payment Method"
              ? `?searchPaymentMethod=${searchValue}&paginate=false`
              : `?paginate=false`
            : dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
              : `?paginate=false`
            : `?paginate=false`
        }`,
        Session()
      );
      let temp = data.data.map((data, index) => [
        index + 1,
        data.datetime,
        data.code,
        data.status,
        data.customer_name,
        data.payment_method,
        data.subtotal,
        // data.discount_type,
        // data.discount_amount,
        data.discount_total,
        data.grand_total,
      ]);
      PrintReport(
        false,
        "Report Order",
        "",
        "",
        "",
        [
          "No",
          "Date & Time",
          "No Transaction",
          "Status",
          "Nama Member",
          "Payment Method",
          "Price",
          // "Discount Type",
          // "Discount Amount",
          "Discount Total",
          "Total",
        ],
        temp
      );
    }
  };

  const prepareEnterDateStart = (val) => {
    setDateStart(val);
    // setSearchValue("");
    fetchData(1, searchValue, val, dateEnd);
  };

  const prepareEnterDateEnd = (val) => {
    setDateEnd(val);
    // setSearchValue("");
    fetchData(1, searchValue, dateStart, val);
  };

  const prepareDeleteOrder = (orderId, orderCode) => {
    setOrderId(orderId);
    setOrderCode(orderCode);
    setOpenDeleteOrder(true);
  };

  const deleteOrder = async () => {
    try {
      await axios.delete(
        `${API_URL}orders/delete/${orderId}`,
        Session()
      );
      fetchData();
      setErrorMsg("Success delete data, refresh page if neccessary!");
      closeDeleteOrderModal();
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-col h-full font-noto-sans">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <ModalDeleteOrderData
        show={openDeleteOrder}
        close={closeDeleteOrderModal}
        submit={deleteOrder}
        orderId={orderCode}
      />
      <div className="flex flex-col md:flex-row overflow-y-hidden overflow-x-auto scrollbar-hide min-h-[3rem]">
        <ReportNavLink />
      </div>

      <div className="w-full flex flex-col mt-3 md:flex-row grow overflow-auto scrollbar-shown">
        <div
          className={`${
            detailShow ? "md:basis-1/2 lg:basis-4/6" : ""
          } basis-full`}
        >
          <div className="bg-white relative rounded-lg overflow-hidden flex h-full flex-col p-3">
            <div className="flex flex-row justify-between sm:justify-start font-nunito-sans my-2 w-full">
              <div className="flex flex-col 2xl:flex-row 2xl:gap-2">
                <div className="flex flex-row gap-2">
                  <div className="text-black">
                    <DropdownSelect
                      header={
                        searchType !== "" && searchType !== undefined
                          ? searchType
                          : "Select Comparison..."
                      }
                      showExpandIcon={true}
                      headerClass="px-2 py-2 border rounded-lg flex flex-row items-center gap-0.5 whitespace-nowrap"
                    >
                      <DropdownOption
                        target={searchType}
                        setTarget={setSearchType}
                        value={"No Transaction"}
                      />
                      <DropdownOption
                        target={searchType}
                        setTarget={setSearchType}
                        value={"Customer Name"}
                      />
                      <DropdownOption
                        target={searchType}
                        setTarget={setSearchType}
                        value={"Payment Method"}
                      />
                    </DropdownSelect>
                  </div>
                  <Search
                    textColor={"text-black"}
                    bgColor={"bg-white"}
                    placeholder={`Search by ${
                      searchType === "No Transaction"
                        ? "no transaction"
                        : searchType === "Customer Name"
                        ? "customer name"
                        : "payment method"
                    }...`}
                    searchValue={searchValue}
                    setSearchValue={showSearchedTablePage}
                  />
                </div>
                <FilterByDate
                  dateStart={dateStart}
                  setDateStart={prepareEnterDateStart}
                  dateEnd={dateEnd}
                  setDateEnd={prepareEnterDateEnd}
                />
                
              </div>
              <DropdownMenuExport
                export={exportAll}
                print={() => printData("report")}
                close={closeAll}
              />
            </div>

            <p className="w-full text-black mb-4">Total All Order : {tableCount} </p>

            {tableData[0] ? (
              <>
                <div
                  id="printArea"
                  className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col mb-3"
                >
                  <TableOrder
                    tableData={tableData}
                    detailData={detailData}
                    deleteOrderData={prepareDeleteOrder}
                    currentPage={currentTablePage}
                  />
                </div>
                <Pagination
                  maxPage={Math.ceil(tableCount / itemsPerPage)}
                  currentPage={currentTablePage}
                  showTablePage={showTablePage}
                />
              </>
            ) : (
              <p className="w-full text-black">No result</p>
            )}
          </div>
        </div>
        {detailOrder && detailShow && (
          <div className="basis-full md:mt-0 md:ml-2 md:basis-1/2 lg:basis-2/6 mt-2">
            <ReportOrderDetail detailOrder={detailOrder} print={printData} detailData={detailData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportOrder;
