import {MdModeEditOutline, MdDeleteOutline} from "react-icons/md";

const TableListProducts = ({tableData, editRow, deleteRow, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }

  return (
    <>
      <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
        <thead className="sticky top-0">
          <tr className="bg-[#F9F9FC] text-black text-left">
            <th className="p-2 whitespace-nowrap">No</th>
            <th className="p-2 whitespace-nowrap">ID Product</th>
            <th className="p-2 whitespace-nowrap">Category</th>
            <th className="p-2 whitespace-nowrap">Product Name</th>
            <th className="p-2 whitespace-nowrap">Price</th>
            <th className="p-2 whitespace-nowrap">Photo</th>
            <th className="p-2 whitespace-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 &&
            tableData.map((row) => (
              <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
                <td className="p-2">{startNumber++}</td>
                <td className="p-2">{row.code}</td>
                <td className="p-2">{row.category}</td>
                <td className="p-2">{row.name}</td>
                <td className="p-2">{row.price}</td>
                <td className="p-2">
                  {row.image !== null ? (
                    <img
                      src={row.image}
                      alt={row.name}
                      className="h-[50px] w-[50px] object-cover"
                    />
                  ) : (
                    <img
                      src="https://dummyimage.com/50/ffffff/000000.png&text=none"
                      alt="Products"
                      className="h-[50px] w-[50px] object-cover"
                    />
                  )}
                </td>
                <td className="p-2">
                  <button
                    onClick={() => {
                      editRow(row);
                    }}
                  >
                    <MdModeEditOutline className="text-red-500 hover:text-red-800" />
                  </button>
                  <button onClick={() => deleteRow(row.id)}>
                    <MdDeleteOutline className="text-red-500 hover:text-red-800" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default TableListProducts;
