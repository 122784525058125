import {MdModeEditOutline, MdDeleteOutline} from "react-icons/md";

const TableCategories = ({tableData, editRow, deleteRow, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }
  return (
    <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
      <thead className="sticky top-0">
        <tr className="bg-[#F9F9FC] text-black text-left">
          <th className="p-2 whitespace-nowrap">No</th>
          <th className="py-2 text-center whitespace-nowrap">ID Category</th>
          <th className="py-2 text-center whitespace-nowrap">Name</th>
          <th className="py-2 text-center whitespace-nowrap">Action</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 &&
          tableData.map((row) => (
            <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
              <td className="p-4 whitespace-nowrap">{startNumber++}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.code}</td>
              <td className="py-2 text-center whitespace-nowrap">{row.name}</td>
              <td className="py-2 mt-2 flex justify-center whitespace-nowrap">
                <button
                  onClick={() => {
                    editRow(row);
                  }}
                >
                  <MdModeEditOutline className="text-red-500 hover:text-red-800" />
                </button>
                <button onClick={() => deleteRow(row.id)}>
                  <MdDeleteOutline className="text-red-500 hover:text-red-800" />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableCategories;
