import TabTitle from "../utils/GeneralFunction";
import {MdAdd} from "react-icons/md";
import TablePresensi from "../components/TablePresensi";
import {useEffect, useState} from "react";
import axios from "axios";
import Pagination from "../components/Pagination";
import ModalCreatePresensi from "../components/ModalCreatePresensi";
import ModalDeletePresensi from "../components/ModalDeletePresensi";
import PresensiDetail from "../components/PresensiDetail";
import Session from "../Session";
import ModalAlert from "../components/ModalAlert";
import Clock from "react-live-clock";
import FilterByDate from "../components/FilterByDate";
import { API_URL } from '../utils/api';

const Presensi = () => {
  TabTitle("Presensi - Kato Haircut");
  // Modal
  const [openAddPresensi, setOpenAddPresensi] = useState(false);
  const closeAddPresensiModal = () => setOpenAddPresensi(false);
  const openAddPresensiModal = () => setOpenAddPresensi(true);
  const [openDeletePresensi, setOpenDeletePresensi] = useState(false);
  const closeDeletePresensiModal = () => setOpenDeletePresensi(false);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setAlertMsg("");
  };
  const openAlertModal = () => setOpenAlert(true);
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [filterStart, setFilterStart] = useState("");
  const [filterEnd, setFilterEnd] = useState("");
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Handle Create
  const [dataEmployee, setDataEmployee] = useState([]);
  const [code, setCode] = useState("");
  const [shift, setShift] = useState("");
  const [status, setStatus] = useState("");
  // Handle Delete
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");
  // Print Function
  const idTable = "tablePresensi";
  // Detail
  const [detailShow, setDetailShow] = useState(false);
  const [detailDateStart, setDetailDateStart] = useState("");
  const [detailDateEnd, setDetailDateEnd] = useState("");
  const [detailPresensi, setDetailPresensi] = useState();
  const [activeId, setActiveId] = useState("");
  const [activeEmployee, setActiveEmployee] = useState("");
  const [loading, setLoading] = useState(false);

  const [alertMsg, setAlertMsg] = useState("");

  const getEmployeeData = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees?paginate=false`,
        Session()
      );
      setDataEmployee(data.data);
    } catch (err) {
      if (!err?.response) {
        setAlertMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setAlertMsg("Unauthorized, please login again!");
      } else {
        setAlertMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchData = async (
    page = currentTablePage,
    dateStart = "",
    dateEnd = ""
  ) => {
    try {
      setLoading(true);
      const {data} = await axios.get(
        `${API_URL}presences${
          dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}`
              : `?page=${page}`
            : `?page=${page}`
        }`,
        Session()
      );
      setTableData(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
      setLoading(false);
    } catch (err) {
      if (!err?.response) {
        setAlertMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setAlertMsg("Unauthorized, please login again!");
      } else {
        setAlertMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    getEmployeeData();
    fetchData();
  }, []);

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(
          `${API_URL}presences/create`,
          {
            code: code,
            shift: shift,
            status: status,
          },
          Session()
        )
        .then((response) => {
          setAlertMsg(response.data.message);
          setOpenAlert(true);
        });
      fetchData();
    } catch (err) {
      setAlertMsg(err.response.data.message);
    }
  };

  const prepareDelete = (id) => {
    setIdDelete(id);
    getDeleteData(id);
    setOpenDeletePresensi(true);
  };

  const getDeleteData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}presences/${id}}`,
        Session()
      );
      setNameDelete(data.data.employee_name);
    } catch (err) {
      if (!err?.response) {
        setAlertMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setAlertMsg("Unauthorized, please login again!");
      } else {
        setAlertMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios
        .delete(
          `${API_URL}presences/destroy/${idDelete}`,
          Session()
        )
        .then((response) => {
          setAlertMsg(response.data.message);
          setOpenAlert(true);
        });
      fetchData();
    } catch (err) {
      if (!err?.response) {
        setAlertMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setAlertMsg("Unauthorized, please login again!");
      } else {
        setAlertMsg("Failed Delete Data");
      }
      setOpenAlert(true);
    }
  };

  return (
    <div className="flex flex-col h-full font-noto-sans">
      <ModalAlert show={openAlert} close={closeAlertModal} message={alertMsg} />
      <ModalCreatePresensi
        show={openAddPresensi}
        close={closeAddPresensiModal}
        dataEmployee={dataEmployee}
        codeValue={code}
        setCodeValue={setCode}
        setShiftValue={setShift}
        setStatusValue={setStatus}
        submit={handleSubmit}
      />
      <ModalDeletePresensi
        show={openDeletePresensi}
        close={closeDeletePresensiModal}
        nameDeleteValue={nameDelete}
        submit={handleDelete}
      />
      <div className="w-full flex flex-col mt-3 md:flex-row grow overflow-y-auto scrollbar-shown">
        <div
          className={`
          basis-full${detailShow ? " md:basis-1/2 lg:basis-3/6" : ""}`}
        >
          <div className="bg-white relative rounded-lg overflow-hidden flex h-full flex-col p-3">
            <div className="flex flex-row my-2 justify-between relative">
              <div className="flex flex-col gap-y-3 mb-3">
                <div className="flex flex-col">
                  <span className="not-italic font-semibold leading-7 text-black text-xl">
                    Date & Time
                  </span>
                  <Clock
                    format="ll, HH:mm:ss"
                    interval={1000}
                    ticking={true}
                    className="h-10 text-3xl text-black font-semibold not-italic"
                  />
                </div>
                <div className="flex flex-col gap-y-2 lg:flex-wrap lg:flex-row lg:gap-2">
                  <button
                    type="submit"
                    className="flex md:hidden items-center px-3 py-2 w-fit bg-black rounded-lg whitespace-nowrap"
                    onClick={openAddPresensiModal}
                  >
                    <MdAdd className="text-white mr-2" />
                    <span>Add Presensi Today</span>
                  </button>
                </div>
                <div className="flex flex-row items-start gap-4">
                  <div className="text-gray-400 flex flex-col">
                    <p>Shift pagi</p>
                    <p>09:00 - 17:00 WIB</p>
                  </div>
                  <div className="text-gray-400 flex flex-col">
                    <p>Shift middle</p>
                    <p>10:30 - 18:30 WIB</p>
                  </div>
                  <div className="text-gray-400 flex flex-col">
                    <p>Shift siang</p>
                    <p>12:00 - 20:00 WIB</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <button
                  type="submit"
                  className="hidden md:flex items-center px-3 py-2 h-fit w-fit bg-black rounded-lg whitespace-nowrap"
                  onClick={openAddPresensiModal}
                >
                  <MdAdd className="text-white mr-2" />
                  <span>Add Presensi Today</span>
                </button>
              </div>
            </div>
            {loading ? (
              <>
                <div className="animate-pulse">
                  <p className="text-gray-400">Loading...</p>
                </div>
              </>
            ) : tableCount ? (
              <>
                <div className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col mb-8">
                  <TablePresensi
                    tableData={tableData}
                    idTable={idTable}
                    deleteRow={prepareDelete}
                    currentPage={currentTablePage}
                  />
                </div>
                <Pagination
                  maxPage={Math.ceil(tableCount / itemsPerPage)}
                  currentPage={currentTablePage}
                  showTablePage={showTablePage}
                />
              </>
            ) : (
              <p className="w-full text-black">No presence(s) recorded</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presensi;
