import {Dialog, Transition} from "@headlessui/react";
import {type} from "@testing-library/user-event/dist/type";
import React, {Fragment, useEffect, useState} from "react";
import {MdClose} from "react-icons/md";

const ModalAlert = ({show, close, message}) => {
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  useEffect(() => {
    if (message === "Unauthorized, please login again!") {
      setIsUnauthorized(true);
    }
  }, [message]);
  const handleClose = () => {
    if (isUnauthorized) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("task");
      localStorage.removeItem("cart");
      window.location = "/login";
    }
    close();
  };
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="div"
                  className="flex justify-center text-lg text-center font-bold leading-6 text-gray-900 pt-3 pb-3 mt-1"
                >
                  <h2>Notification</h2>
                  <div
                    onClick={handleClose}
                    className="rounded-full p-0.5 top-2 right-2 bg-gray-200 absolute"
                    role="button"
                  >
                    <MdClose className="relative" />
                  </div>
                </Dialog.Title>
                <div className="mt-2 border-t-2">
                  <div className="mt-6 px-6 pb-6 flex flex-row justify-center text-center">
                    <h3>{message}</h3>
                  </div>
                  <div className="mt-2 px-6 pb-6 flex flex-row justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalAlert;
