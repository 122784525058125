import React from "react";
import {MdDeleteOutline, MdModeEditOutline} from "react-icons/md";

const TableActivity = ({tableData, editRow, deleteRow, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 9 + 1;
  }

  const addDots = (nStr) => {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  };

  const capitalizeEachWord = (sentence) => {
    const words = sentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  return (
    <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
      <thead className="sticky top-0">
        <tr className="bg-[#F9F9FC] text-black text-left">
          <th className="py-2 whitespace-nowrap">No</th>
          <th className="py-2 whitespace-nowrap">ID Activity</th>
          <th className="py-2 whitespace-nowrap">Activity Name</th>
          <th className="py-2 text-center whitespace-nowrap">Commission Type</th>
          <th className="py-2 pr-12 text-right whitespace-nowrap">Commission Value</th>
          <th className="py-2 pl-12 whitespace-nowrap">Action</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 &&
          tableData.map((row) => (
            <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
              <td className="py-4 whitespace-nowrap">{startNumber++}</td>
              <td className="py-2 whitespace-nowrap">{row.code}</td>
              <td className="py-2 whitespace-nowrap">{row.name}</td>
              <td className="py-2 text-center whitespace-nowrap">
                {capitalizeEachWord(row.type)}
              </td>
              <td className="py-2 pr-12 text-right whitespace-nowrap">
                {row.type === "percent"
                  ? row.amount + " %"
                  : "Rp " + addDots(row.amount)}
              </td>
              <td className="py-2 pl-12 whitespace-nowrap">
                <button
                  onClick={() => {
                    editRow(row.id);
                  }}
                >
                  <MdModeEditOutline className="text-red-500 hover:text-red-800" />
                </button>
                <button onClick={() => deleteRow(row.id)}>
                  <MdDeleteOutline className="text-red-500 hover:text-red-800" />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableActivity;
