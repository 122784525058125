import {MdAdd} from "react-icons/md";
import React, {useState, useEffect} from "react";
import TabTitle from "../utils/GeneralFunction";
import TableListProducts from "../components/TableListProducts";
import axios from "axios";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import ProductCategoryList from "../components/ProductCategoryList";
import ModalCreateProduct from "../components/ModalCreateProduct";
import ModalDeleteProduct from "../components/ModalDeleteProduct";
import ModalEditProduct from "../components/ModalEditProduct";
import Session from "../Session";
import ModalAlert from "../components/ModalAlert";
import { API_URL } from '../utils/api';

const ProductList = () => {
  TabTitle("List Product - Kato Haircut");
  // Modal
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const closeAddProductModal = () => setOpenAddProduct(false);
  const openAddProductModal = () => setOpenAddProduct(true);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const closeEditProductModal = () => {
    setOpenEditProduct(false);
    setImageEdit();
  };
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const closeDeleteProductModal = () => setOpenDeleteProduct(false);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [totalResult, setTotalResult] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // Search
  const [searchValue, setSearchValue] = useState();
  const [currentCategory, setCurrentCategory] = useState("All");
  const [currentIdCategory, setCurrentIdCategory] = useState(null);
  // Handle Create
  const [dataCategory, setDataCategory] = useState([]);
  const [category, setCategory] = useState();
  const [name, setName] = useState("");
  const [price, setPrice] = useState();
  const [image, setImage] = useState();
  // Handle Edit
  const [idEdit, setIdEdit] = useState("");
  const [codeEdit, setCodeEdit] = useState("");
  const [categoryEdit, setCategoryEdit] = useState();
  const [nameEdit, setNameEdit] = useState("");
  const [priceEdit, setPriceEdit] = useState();
  const [imageEdit, setImageEdit] = useState();
  // Handle Delete
  const [idDelete, setIdDelete] = useState("");
  const [nameDelete, setNameDelete] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const fetchDataCategory = async () => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/categories/all`,
        Session()
      );
      setDataCategory(data.data);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    fetchDataCategory();
    fetchAllCategoryProduct();
  }, []);

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    if (currentCategory === "All") {
      fetchAllCategoryProduct(searchValue, page);
    } else {
      fetchSpecificCategoryProduct(currentIdCategory, searchValue, page);
    }
  };

  const showSearchedTablePage = (searchValue) => {
    setSearchValue(searchValue);
    setCurrentTablePage(1);
    if (currentCategory === "All") {
      fetchAllCategoryProduct(searchValue, 1);
    } else {
      fetchSpecificCategoryProduct(currentIdCategory, searchValue, 1);
    }
  };

  const fetchSpecificCategoryProduct = async (id, name, page) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/categories/products-by-category/${id}${
          name !== "" && name !== undefined
            ? page !== "" && page !== undefined
              ? `?name=${name}&page=${page}`
              : `?name=${name}`
            : page !== "" && page !== undefined
            ? `?page=${page}`
            : ``
        }`,
        Session()
      );
      setTableData(data.data);
      setTotalResult(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchAllCategoryProduct = async (name, page) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products${
          name !== "" && name !== undefined
            ? page !== "" && page !== undefined
              ? `?name=${name}&page=${page}`
              : `?name=${name}`
            : page !== "" && page !== undefined
            ? `?page=${page}`
            : ``
        }`,
        Session()
      );
      setTableData(data.data);
      setTotalResult(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleChangeCategory = (val) => {
    setCurrentIdCategory(val);
    setCurrentTablePage(1);
    val !== "All"
      ? fetchSpecificCategoryProduct(val, searchValue, 1)
      : fetchAllCategoryProduct(searchValue, 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("category_id", category);
    formData.append("name", name);
    formData.append("price", price);
    try {
      axios.post(
        `${API_URL}products/create`,
        formData,
        Session()
      );
      setImage("");
      setName("");
      setCategory("");
      setPrice("");
      fetchAllCategoryProduct();
      setErrorMsg(
        "Succesfully added product, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed add data, check your input again!");
      }
      setOpenAlert(true);
    }
  };

  const prepareEdit = (val) => {
    setPriceEdit(price);
    setIdEdit(val.id);
    setCodeEdit(val.code);
    setNameEdit(val.name);
    setCategoryEdit(val.category);
    setImageEdit(val.image);
    getEditData(val);
    setOpenEditProduct(true);
  };

  const getEditData = async (val) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/${val.id}}`,
        Session()
      );
      setCodeEdit(data.data.code);
      setNameEdit(data.data.name);
      setCategoryEdit(data.data.category);
      setPriceEdit(data.data.price_unformat);
      setImageEdit(data.data.image);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      let i;
      let category_id = 0;
      for (i in dataCategory) {
        if (dataCategory[i].name === categoryEdit) {
          category_id = dataCategory[i].id;
        }
      }

      let formData = new FormData();
      formData.append("code", codeEdit);
      formData.append("category_id", category_id);
      formData.append("name", nameEdit);
      formData.append("price", priceEdit);
      formData.append("image", imageEdit);
      formData.append("_method", "PUT");

      await axios.post(
        `${API_URL}products/update/${idEdit}`,
        formData,
        Session()
      );
      fetchAllCategoryProduct();
      setErrorMsg(
        "Succesfully edited product data, if data didn't match with your editing you must refresh your browser"
      );
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed update data");
      }
      setOpenAlert(true);
    }
  };

  const prepareDelete = (id) => {
    setIdDelete(id);
    getDeleteData(id);
    setOpenDeleteProduct(true);
  };

  const getDeleteData = async (id) => {
    try {
      const {data} = await axios.get(
        `${API_URL}products/${id}}`,
        Session()
      );
      setNameDelete(data.data.name);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}products/delete/${idDelete}`,
        Session()
      );
      fetchDataCategory();
      fetchAllCategoryProduct();
      setErrorMsg("Succesfully deleted product");
      setOpenAlert(true);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Failed delete data");
      }
      setOpenAlert(true);
    }
  };

  return (
    <div className="w-full flex flex-col h-full overflow-auto scrollbar-shown">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <ModalCreateProduct
        show={openAddProduct}
        close={closeAddProductModal}
        dataCategory={dataCategory}
        categoryValue={category}
        setCategoryValue={setCategory}
        nameValue={name}
        setNameValue={setName}
        priceValue={price}
        setPriceValue={setPrice}
        imageValue={image}
        setImageValue={setImage}
        submit={handleSubmit}
      />
      <ModalEditProduct
        show={openEditProduct}
        close={closeEditProductModal}
        dataCategory={dataCategory}
        codeEditValue={codeEdit}
        setCodeEditValue={setCodeEdit}
        categoryEditValue={categoryEdit}
        setCategoryEditValue={setCategoryEdit}
        nameEditValue={nameEdit}
        setNameEditValue={setNameEdit}
        priceEditValue={priceEdit}
        setPriceEditValue={setPriceEdit}
        imageEditValue={imageEdit}
        setImageEditValue={setImageEdit}
        submit={handleEdit}
      />
      <ModalDeleteProduct
        show={openDeleteProduct}
        close={closeDeleteProductModal}
        nameDeleteValue={nameDelete}
        submit={handleDelete}
      />
      <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col">
        <div className="flex justify-between">
          <Search
            textColor={"text-black"}
            bgColor={"bg-white"}
            placeholder={"Search by product name..."}
            searchValue={searchValue}
            setSearchValue={showSearchedTablePage}
          />
          <button
            type="submit"
            className="flex flex-row ml-2 items-center sm:ml-auto h-10 px-3 py-2 bg-black rounded-lg whitespace-nowrap"
            onClick={openAddProductModal}
          >
            <MdAdd className="text-white mr-2" />
            <span>Add Product</span>
          </button>
        </div>
        <div>
          <ProductCategoryList
            dataCategory={dataCategory}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
            handleChangeCategory={handleChangeCategory}
          />
        </div>
        {totalResult ? (
          <>
            <div className="h-full mb-3 overflow-y-auto scrollbar-shown">
              <TableListProducts
                tableData={tableData}
                dataCategory={dataCategory}
                editRow={prepareEdit}
                deleteRow={prepareDelete}
                currentPage={currentTablePage}
              />
            </div>
            <Pagination
              maxPage={Math.ceil(totalResult / itemsPerPage)}
              currentPage={currentTablePage}
              showTablePage={showTablePage}
            />
          </>
        ) : (
          <p className="w-full text-black">Waiting for Data</p>
        )}
      </div>
    </div>
  );
};

export default ProductList;
