import React from "react";

const TableEmployeeSingle = ({tableData, currentPage}) => {
  let startNumber = 1;

  if (currentPage !== 1) {
    startNumber = (currentPage - 1) * 15 + 1;
  }

  return (
    <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
      <thead className="sticky top-0">
        <tr className="bg-[#F9F9FC] text-black text-left">
          <th className="py-2 whitespace-nowrap">No</th>
          <th className="py-2 whitespace-nowrap">Transaction ID</th>
          <th className="py-2 whitespace-nowrap">Date</th>
          <th className="py-2 whitespace-nowrap">Product Name</th>
          <th className="py-2 whitespace-nowrap">Price</th>
          <th className="py-2 whitespace-nowrap">Category</th>
          <th className="py-2 whitespace-nowrap">Total Fee</th>
        </tr>
      </thead>
      <tbody>
        {tableData.length > 0 &&
          tableData.map((row) => (
            <tr key={row.id} className="even:bg-[#F9F9FC] text-black">
              <td className="py-4 whitespace-nowrap">{startNumber++}</td>
              <td className="py-2 whitespace-nowrap">{row.code}</td>
              <td className="py-2 whitespace-nowrap">{row.date}</td>
              <td className="py-2 whitespace-nowrap">{row.product_name}</td>
              <td className="py-2 whitespace-nowrap">{row.price}</td>
              <td className="py-2 whitespace-nowrap">{row.activities}</td>
              <td className="py-2 whitespace-nowrap">{row.fee}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableEmployeeSingle;
