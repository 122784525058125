import TabTitle from "../utils/GeneralFunction";
import axios from "axios";
import React, {useEffect, useState} from "react";
import ModalAlert from "../components/ModalAlert";
import Pagination from "../components/Pagination";
import Session from "../Session";
import TableEmployeePresence from "../components/TableEmployeePresence";
import Search from "../components/Search";
import PresensiDetail from "../components/PresensiDetail";
import PrintReport from "../utils/PrintReport";
import {utils, writeFileXLSX} from "xlsx";
import { API_URL } from '../utils/api';

const EmployeePresence = () => {
  TabTitle("Data Presence - Kato Haircut");
  // Modal
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
  };
  const [errorMsg, setErrorMsg] = useState("");
  // Search
  const [searchValue, setSearchValue] = useState("");
  // Data
  const [dataPresence, setDataPresence] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // Detail
  const [displayDetail, setDisplayDetail] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [activeName, setActiveName] = useState("");
  const [detailDataPresence, setDetailDataPresence] = useState([]);
  const [totalDetailPresence, setTotalDetailPresence] = useState("");
  const [tableDetailCount, setTableDetailCount] = useState(null);
  const [itemsPerPageDetail, setItemsPerPageDetail] = useState(null);
  const [currentDetailPage, setCurrentDetailPage] = useState(1);
  const [detailDateStart, setDetailDateStart] = useState("");
  const [detailDateEnd, setDetailDateEnd] = useState("");
  // Print
  const idTable = "tableRecapPresence";

  const fetchData = async (page = currentPage, search = searchValue) => {
    try {
      const {data} = await axios.get(
        `${API_URL}employees${
          search !== "" && search !== undefined
            ? `?name=${search}&page=${page}`
            : `?page=${page}`
        }`,
        Session()
      );
      setDataPresence(data.data);
      setTableCount(data.meta.total);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const fetchDetailData = async (
    page = currentDetailPage,
    id = activeId,
    dateStart,
    dateEnd
  ) => {
    try {
      const {data} = await axios.get(
        `${API_URL}presences/${id}${
          dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}&page=${page}`
              : `?from=${dateStart}&page=${page}`
            : `?page=${page}`
        }`,
        Session()
      );
      setDetailDataPresence(data.data);
      setTotalDetailPresence(data.count);
      setTableDetailCount(data.meta.total);
      setItemsPerPageDetail(data.meta.per_page);
    } catch (err) {
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const showTablePage = (page) => {
    setCurrentPage(page);
    fetchData(page, searchValue);
  };

  const showSearchedTablePage = (val) => {
    setSearchValue(val);
    setCurrentPage(1);
    fetchData(1, val);
  };

  const showDetailPresence = (valId, valName) => {
    if (activeId !== valId) {
      setCurrentDetailPage(1);
      fetchDetailData(1, valId, detailDateStart, detailDateEnd);
      setActiveId(valId);
      setActiveName(valName);
      setDisplayDetail(true);
    } else {
      setActiveId("");
      setActiveName("");
      setDisplayDetail(false);
    }
  };

  const showDetailTablePage = (page) => {
    setCurrentDetailPage(page);
    fetchDetailData(page, activeId, detailDateStart, detailDateEnd);
  };

  const enterFilterDateStart = (val) => {
    setDetailDateStart(val);
    fetchDetailData(currentDetailPage, activeId, val, detailDateEnd);
  };

  const enterFilterDateEnd = (val) => {
    setDetailDateEnd(val);
    fetchDetailData(currentDetailPage, activeId, detailDateStart, val);
  };

  const printAll = async () => {
    let {data} = await axios.get(
      `${API_URL}presences/${activeId}${
        detailDateStart !== "" && detailDateStart !== undefined
          ? detailDateEnd !== "" && detailDateEnd !== undefined
            ? `?from=${detailDateStart}&to=${detailDateEnd}&paginate=false`
            : `?from=${detailDateStart}&paginate=false`
          : `?paginate=false`
      }`,
      Session()
    );
    let employee = await axios.get(
      `${API_URL}employees/${activeId}`,
      Session()
    );
    let code = employee.data.data.code;
    let name = employee.data.data.name;
    let total = employee.data.data.total_presence;
    let temp = data.data.map((data, index) => [
      index + 1,
      name,
      data.shift,
      data.coming_time,
      data.return_time ? data.return_time : "-",
    ]);
    PrintReport(
      false,
      `${code} - ${name}`,
      "REKAP PRESENSI",
      ["ID Employee", "Nama Employee", "Total Presensi"],
      [code, name, total],
      ["No", "Nama", "Shift", "Jam Datang", "Jam Pulang"],
      temp
    );
  };

  const exportAll = async () => {
    let {data} = await axios.get(
      `${API_URL}presences/${activeId}${
        detailDateStart !== "" && detailDateStart !== undefined
          ? detailDateEnd !== "" && detailDateEnd !== undefined
            ? `?from=${detailDateStart}&to=${detailDateEnd}&paginate=false`
            : `?from=${detailDateStart}&paginate=false`
          : `?paginate=false`
      }`,
      Session()
    );
    let employee = await axios.get(
      `${API_URL}employees/${activeId}`,
      Session()
    );
    let code = employee.data.data.code;
    let name = employee.data.data.name;
    let temp = data.data.map((data, index) => [
      index + 1,
      code,
      data.employee_name,
      data.shift,
      data.coming_time,
      data.return_time ? data.return_time : "-",
    ]);

    const headings = [
      ["No", "ID Employee", "Nama", "Shift", "Jam Datang", "Jam Pulang"],
    ];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(temp);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, temp, {origin: "A4", skipHeader: true});
    utils.book_append_sheet(wb, ws, `Report Presensi ${name}`);
    writeFileXLSX(wb, `Report Presensi ${name}.xlsx`);
  };

  const close = () => {
    setActiveId("");
    setActiveName("");
    setDisplayDetail(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full flex flex-col h-full grow overflow-auto scrollbar-shown">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <div className="w-full flex flex-col mt-3 md:flex-row grow overflow-auto scrollbar-shown">
        <div
          className={`${
            displayDetail ? "md:basis-1/2 lg:basis-4/6" : ""
          } basis-full`}
        >
          <div className="bg-white w-full p-5 rounded-lg overflow-hidden flex h-full flex-col">
            <div className="flex justify-between">
              <Search
                textColor={"text-black"}
                bgColor={"bg-white"}
                placeholder={"Search by name..."}
                searchValue={searchValue}
                setSearchValue={showSearchedTablePage}
              />
            </div>
            {tableCount ? (
              <>
                <div className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col mb-3">
                  <TableEmployeePresence
                    tableData={dataPresence}
                    idTable={idTable}
                    detail={showDetailPresence}
                    currentPage={currentPage}
                  />
                </div>
                <Pagination
                  maxPage={Math.ceil(tableCount / itemsPerPage)}
                  currentPage={currentPage}
                  showTablePage={showTablePage}
                />
              </>
            ) : (
              <p className="w-full text-black">Waiting for Data</p>
            )}
          </div>
        </div>
        {displayDetail && (
          <div className="basis-full md:mt-0 md:ml-2 md:basis-1/2 lg:basis-2/6 mt-2">
            <PresensiDetail
              detailPresensi={detailDataPresence}
              employeeName={activeName}
              totalPresensi={totalDetailPresence}
              dateStart={detailDateStart}
              setDateStart={enterFilterDateStart}
              dateEnd={detailDateEnd}
              setDateEnd={enterFilterDateEnd}
              maxPage={Math.ceil(tableDetailCount / itemsPerPageDetail)}
              currentPage={currentDetailPage}
              showTablePage={showDetailTablePage}
              exportAll={exportAll}
              printAll={printAll}
              close={close}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeePresence;
