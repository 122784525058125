import {
  MdOutlineDashboard,
  MdAddShoppingCart,
  MdOutlineLocalOffer,
  MdOutlineShoppingBag,
  MdOutlineAssessment,
  MdOutlineAssignment,
  MdOutlineGroup,
  MdOutlineModeEditOutline,
  MdOutlineHowToReg,
  MdOutlineSettings,
  MdOutlineHeadsetMic,
  MdHistory,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";
import {ReactComponent as ProductDownload} from "../img/product-downloadable.svg";
import {ReactComponent as PercentCircle} from "../img/mdi_percent-circle-outline.svg";
import {ReactComponent as SecurePayment} from "../img/ri_secure-payment-line.svg";
import {NavLink} from "react-router-dom";
import {useState} from "react";
import {getRole} from "../Session";

const Sidebar = ({show, setHide}) => {
  const [menuProductOpen, setMenuProductOpen] = useState(false);
  const [menuProductActive, setMenuProductActive] = useState(false);
  const [menuReportOpen, setMenuReportOpen] = useState(false);
  const [menuReportActive, setMenuReportActive] = useState(false);
  const [menuEmployeeOpen, setMenuEmployeeOpen] = useState(false);
  const [menuEmployeeActive, setMenuEmployeeActive] = useState(false);
  const [menuSettingOpen, setMenuSettingOpen] = useState(false);
  const [menuSettingActive, setMenuSettingActive] = useState(false);

  return (
    <>
      {show && (
        <>
          <div className="bg-primary-100 text-white fixed top-20 left-0 bottom-0 md:relative md:top-0 min-w-[270px] z-[21] p-3 overflow-y-auto scrollbar-auto-hide">
            <ul className="relative w-full min-h-full">
              {getRole() === "admin" ? (
                <li className="rounded-lg mb-3 last:mb-0" role="button">
                  <NavLink
                    to="/cashier"
                    className={({isActive}) =>
                      isActive
                        ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                        : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                    }
                    onClick={() => {
                      setMenuProductActive(false);
                      setMenuEmployeeActive(false);
                      setMenuReportActive(false);
                      setMenuSettingActive(false);
                    }}
                  >
                    <MdAddShoppingCart className="text-2xl lg:mr-3" />
                    <span>Cashier</span>
                  </NavLink>
                </li>
              ) : (
                <>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <NavLink
                      to="/"
                      className={({isActive}) =>
                        isActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }
                      onClick={() => {
                        setMenuProductActive(false);
                        setMenuEmployeeActive(false);
                        setMenuReportActive(false);
                        setMenuSettingActive(false);
                      }}
                    >
                      <MdOutlineDashboard className="text-2xl lg:mr-3" />
                      <span>Dashboard</span>
                    </NavLink>
                  </li>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <NavLink
                      to="/cashier"
                      className={({isActive}) =>
                        isActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }
                      onClick={() => {
                        setMenuProductActive(false);
                        setMenuEmployeeActive(false);
                        setMenuReportActive(false);
                        setMenuSettingActive(false);
                      }}
                    >
                      <MdAddShoppingCart className="text-2xl lg:mr-3" />
                      <span>Cashier</span>
                    </NavLink>
                  </li>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <div
                      className={`${
                        menuProductActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }`}
                      onClick={() => {
                        setMenuProductOpen(!menuProductOpen);
                        setMenuReportOpen(false);
                        setMenuEmployeeOpen(false);
                        setMenuSettingOpen(false);
                      }}
                    >
                      <ProductDownload className="text-2xl lg:mr-3" />
                      <span>Product</span>
                      {menuProductOpen ? (
                        <MdOutlineExpandLess className="ml-auto text-2xl -mr-2" />
                      ) : (
                        <MdOutlineExpandMore className="ml-auto text-2xl -mr-2" />
                      )}
                    </div>
                    <ul className={`${menuProductOpen ? "" : "hidden"} ml-4`}>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/product/category"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(true);
                            setMenuEmployeeActive(false);
                            setMenuReportActive(false);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineLocalOffer className="text-2xl lg:mr-3" />
                          <span>Category</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/product/list"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(true);
                            setMenuEmployeeActive(false);
                            setMenuReportActive(false);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineShoppingBag className="text-2xl lg:mr-3" />
                          <span>List Product</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/product/sell"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(true);
                            setMenuEmployeeActive(false);
                            setMenuReportActive(false);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineShoppingBag className="text-2xl lg:mr-3" />
                          <span>Product Sell</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <div
                      className={`${
                        menuReportActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }`}
                      onClick={() => {
                        setMenuProductOpen(false);
                        setMenuReportOpen(!menuReportOpen);
                        setMenuEmployeeOpen(false);
                        setMenuSettingOpen(false);
                      }}
                    >
                      <MdOutlineAssessment className="text-2xl lg:mr-3" />
                      <span>Report</span>
                      {menuReportOpen ? (
                        <MdOutlineExpandLess className="ml-auto text-2xl -mr-2" />
                      ) : (
                        <MdOutlineExpandMore className="ml-auto text-2xl -mr-2" />
                      )}
                    </div>
                    <ul className={`${menuReportOpen ? "" : "hidden"} ml-4`}>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/report/order"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(true);
                            setMenuEmployeeActive(false);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineAssignment className="text-2xl lg:mr-3" />
                          <span>Order</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/report/commission"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(true);
                            setMenuEmployeeActive(false);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineAssignment className="text-2xl lg:mr-3" />
                          <span>Commission</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/report/transaction"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(true);
                            setMenuEmployeeActive(false);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineAssignment className="text-2xl lg:mr-3" />
                          <span>Transaction</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <NavLink
                      to="/customers"
                      className={({isActive}) =>
                        isActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }
                      onClick={() => {
                        setMenuProductActive(false);
                        setMenuEmployeeActive(false);
                        setMenuReportActive(false);
                        setMenuSettingActive(false);
                      }}
                    >
                      <MdOutlineGroup className="text-2xl lg:mr-3" />
                      <span>Customers</span>
                    </NavLink>
                  </li>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <NavLink
                      to="/presensi"
                      className={({isActive}) =>
                        isActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }
                      onClick={() => {
                        setMenuProductActive(false);
                        setMenuEmployeeActive(false);
                        setMenuReportActive(false);
                        setMenuSettingActive(false);
                      }}
                    >
                      <MdOutlineModeEditOutline className="text-2xl lg:mr-3" />
                      <span>Presence</span>
                    </NavLink>
                  </li>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <div
                      className={`${
                        menuEmployeeActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }`}
                      onClick={() => {
                        setMenuProductOpen(false);
                        setMenuReportOpen(false);
                        setMenuEmployeeOpen(!menuEmployeeOpen);
                        setMenuSettingOpen(false);
                      }}
                    >
                      <MdOutlineHowToReg className="text-2xl lg:mr-3" />
                      <span>Employee</span>
                      {menuEmployeeOpen ? (
                        <MdOutlineExpandLess className="ml-auto text-2xl -mr-2" />
                      ) : (
                        <MdOutlineExpandMore className="ml-auto text-2xl -mr-2" />
                      )}
                    </div>
                    <ul className={`${menuEmployeeOpen ? "" : "hidden"} ml-4`}>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/employee/presence"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(false);
                            setMenuEmployeeActive(true);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineShoppingBag className="text-2xl lg:mr-3" />
                          <span>Data Presence</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/employee/list"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(false);
                            setMenuEmployeeActive(true);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineShoppingBag className="text-2xl lg:mr-3" />
                          <span>Employee Lists</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/employee/jobs"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(false);
                            setMenuEmployeeActive(true);
                            setMenuSettingActive(false);
                          }}
                        >
                          <MdOutlineLocalOffer className="text-2xl lg:mr-3" />
                          <span>Jobs Category</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="rounded-lg mb-3 last:mb-0" role="button">
                    <div
                      className={`${
                        menuSettingActive
                          ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                          : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                      }`}
                      onClick={() => {
                        setMenuProductOpen(false);
                        setMenuReportOpen(false);
                        setMenuEmployeeOpen(false);
                        setMenuSettingOpen(!menuSettingOpen);
                      }}
                    >
                      <MdOutlineSettings className="text-2xl lg:mr-3" />
                      <span>Settings</span>
                      {menuSettingOpen ? (
                        <MdOutlineExpandLess className="ml-auto text-2xl -mr-2" />
                      ) : (
                        <MdOutlineExpandMore className="ml-auto text-2xl -mr-2" />
                      )}
                    </div>
                    <ul className={`${menuSettingOpen ? "" : "hidden"} ml-4`}>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/setting/admin"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(false);
                            setMenuEmployeeActive(false);
                            setMenuSettingActive(true);
                          }}
                        >
                          <MdOutlineHeadsetMic className="text-2xl lg:mr-3" />
                          <span>Admin</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3" role="button">
                        <NavLink
                          to="/payment-method"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(false);
                            setMenuEmployeeActive(false);
                            setMenuSettingActive(true);
                          }}
                        >
                          <SecurePayment className="text-2xl lg:mr-3" />
                          <span>Payment Methods</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/product/commission"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(false);
                            setMenuEmployeeActive(false);
                            setMenuSettingActive(true);
                          }}
                        >
                          <PercentCircle className="text-2xl lg:mr-3" />
                          <span>Commission</span>
                        </NavLink>
                      </li>
                      <li className="rounded-lg mt-3">
                        <NavLink
                          to="/setting/log"
                          className={({isActive}) =>
                            isActive
                              ? "flex items-center bg-white text-black hover:bg-slate-100 font-semibold px-5 py-4 rounded-lg"
                              : "flex items-center bg-primary-100 text-white hover:bg-primary-500 font-semibold px-5 py-4 rounded-lg"
                          }
                          onClick={() => {
                            setMenuProductActive(false);
                            setMenuReportActive(false);
                            setMenuEmployeeActive(false);
                            setMenuSettingActive(true);
                          }}
                        >
                          <MdHistory className="text-2xl lg:mr-3" />
                          <span>Log Login</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div
            className="fixed top-20 left-0 bottom-0 right-0 md:relative md:top-0 bg-primary-100 bg-opacity-25 z-20"
            onClick={() => setHide(!show)}
          ></div>
        </>
      )}
    </>
  );
};

export default Sidebar;
